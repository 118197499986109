import {
  Col,
  ConfigProvider,
  Row,
  Tabs
} from "antd";
import {  useRef, useState, useEffect } from "react";


export const ScrollingTabs = (props) => {
  const [tabs, setTabs] = useState([]);
  const [visibility, setVisibility] = useState([]);
  const [flag, setFlag] = useState(0);

  function setElementVisibility(isVisible, index) {

    visibility[index] = isVisible;
    setFlag(flag+1);

    //setVisibility(newVisibility);
  }

  useEffect(() => {

    if (props.items.length > 0) {
      const newTabs = [];
      const newVisibility = [];

      props.items.forEach((item, index) => {
      
        const id = index.toString();      
        newVisibility.push(false);

        newTabs.push({
          
          id: id,
          isVisible: true,
          ...item
        });
      }); 

      setVisibility(newVisibility);
      setTabs(newTabs);
    }

  }, [props.items.length]); 

  return (
    <>
      {
        tabs.length > 0 ?
          <>
            <NavBar items={tabs} visibility={visibility} flag={flag}/>
            {
              props.items.map((item, index) => 
      
           
                <VisibilityWrapper key={index} uid={index} index={index} setVisibility={setElementVisibility}>{item.child}</VisibilityWrapper>)

          }
          </>
        :
        <></>
      }
    </>
  );
};

const NavBar = (props) => {

  const [activeKey, setActiveKey] = useState("0");
  const [ignore, setIgnore] = useState(false);
  const tabRef = useRef();

  useEffect(() => {

    if (!ignore) {
      for (let index = 0; index < props.visibility.length; index++) {
        const isVisible = props.visibility[index];

        if (isVisible) {
          setActiveKey(props.items[index].id);  
          break;
        }
      }
    }
  }, [props]);

  const handleClick = (key) => {
    const element = document.getElementById(key);

    //dampen useIsVisible 
    if (element) {
      // Will scroll smoothly to the top of the next section
      setIgnore(true);
      var viewportOffset = element.getBoundingClientRect();
      // these are relative to the viewport, i.e. the window
      var top = viewportOffset.top; 
      window.scrollBy({
        top: top - 90,
        left: 0,
        behavior: "smooth",
      });
      //element.scrollIntoView({ behavior: 'smooth' });

      setActiveKey(key); 
    }
  };

  useEffect(() => {

    if (ignore) {
      setTimeout(function () {
        setIgnore(false);
      }, 1000);      
    }
    
  }, [activeKey]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorTextHeading: "#0CC2CB",
            colorPrimaryHover: "#0CC2CB",
            colorPrimaryActive: "black"
          },
        },
      }}
    >    
      <div ref={tabRef} className="sticky top-0 z-50 max-w-full">
        <Row>
          <Col span={24}>
            <Tabs 
              style={{backgroundColor:"white"}}
              onTabClick={handleClick}
              activeKey={activeKey}
              items={props.items.map((item, i) => {
                const key = item.id || item.label.toLowerCase();
                const icon = (activeKey === item.id) ? <div style={{color:"red"}}>{item.ActiveIcon}</div> : item.DefaultIcon;
                return {
                  label: 
                    <Row className="flex items-center">
                        {icon}
                        {(activeKey === item.id) ? <div style={{marginLeft:"5px", color:"#0CC2CB"}}>{item.label}</div> : <div style={{marginLeft:"5px"}}>{item.label}</div> }
                    </Row>
                  ,
                  key,
                  children: <></>
                };
              }
            )}
            />
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  );
}

const VisibilityWrapper = (props) => {

  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {

    props.setVisibility(isVisible, props.index);

  }, [isVisible]);

  return (
    <div id={props.uid} key={props.uid} ref={ref}>
      {props.children}
    </div>
  );
}

function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}