import {
  Image,
  Table,
  Col,
  ConfigProvider,
  Row,
  Skeleton,
  Tag,
  Tabs,
  Typography,
  Progress,
} from "antd";
import Page from "@screens/Page";
//import FinancialsTable from "@components/FinancialsTable";
import useFundQuery from "@hooks/api/useFundQuery";
import { COLOR_DARK_PRIMARY } from "@constants/colors";
import type { ColumnsType } from "antd/es/table";
import PeopleTable from "@components/PeopleTable";
import log from "loglevel";
import useActiveFund from "@hooks/useActiveFund";
import { useState, useEffect } from "react";
import {ScrollingTabs} from "@components/ScrollingTabs";

import { ReactComponent as OverviewActive } from "@icons/fund/Fund_Overview_active.svg";
import { ReactComponent as OverviewDefault } from "@icons/fund/Fund_Overview_default.svg";
//import { ReactComponent as FinancialsActive } from "@icons/fund/Financials_Overview_active.svg";
//import { ReactComponent as FinancialsDefault } from "@icons/fund/Financials_Overview_default.svg";
import { ReactComponent as AdvisoryBoardActive } from "@icons/fund/Advisory_Board_active.svg";
import { ReactComponent as AdvisoryBoardDefault } from "@icons/fund/Advisory_Board_default.svg";
import { ReactComponent as InvestmentTeamActive } from "@icons/fund/Investment_Team_active.svg";
import { ReactComponent as InvestmentTeamDefault } from "@icons/fund/Investment_Team_default.svg";

const { Text } = Typography;

const FundDetail = () => {
  const { activeFundId } = useActiveFund();
  const { data, isLoading } = useFundQuery(activeFundId);
  const [tabs, setTabs] = useState([]);

  log.debug("FUND DATA: ", data);

  useEffect(() => {

    if (data) {

      setTabs([
        { 
          DefaultIcon: <OverviewDefault />, 
          ActiveIcon: <OverviewActive />, 
          label: "Fund Overview", 
          child: <FundAndGoals data={data}/>
        },
        { 
          DefaultIcon: <InvestmentTeamDefault />, 
          ActiveIcon: <InvestmentTeamActive />, 
          label: "Investment Team", 
          child: <W><PeopleTable label={"Investment Team"} list={data.investmentTeam}/></W>
        },
        { 
          DefaultIcon: <AdvisoryBoardDefault />, 
          ActiveIcon: <AdvisoryBoardActive />, 
          label: "Advisory Board", 
          child: <W><PeopleTable label={"Advisory Board"} list={data.advisoryBoard}/></W>
        },/*
        { 
          DefaultIcon: <FinancialsDefault />, 
          ActiveIcon: <FinancialsActive />, 
          label: "Financials", 
          child: <W><FinancialsTable /></W>
        },*/
      ]);
    }
  }, [data, isLoading ]);


  return (
    <Page siderIndex="-1">
      <ConfigProvider
        theme={{
          components: {},
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
        }}
      >
        {isLoading ? (
          <Skeleton active={isLoading} />
        ) : (
          <>
            <Row>
              <Col>
                <Image preview={false} width="48px" src={data?.imageUrl}/>
              </Col>
              <Col>
                <Row>
                  <Text
                    style={{
                      fontWeight: 700,
                      color: COLOR_DARK_PRIMARY,
                      fontSize: "18px",
                    }}
                  >
                    {data.name}
                  </Text>
                </Row>
                <Row>
                  <a
                    style={{ fontWeight: 500, color: "#0078AF" }}
                    href={data.website}
                  >
                    {data.website}
                  </a>
                </Row>
              </Col>
            </Row>
            <div>
              <ScrollingTabs items={tabs} style={{marginTop:"50px"}} />
            </div>
            
          </>
        )}
      </ConfigProvider>
    </Page>
  );
};

function FundAndGoals(props) {

  return (
    <>
      <W>
        <FundOverview
          geographies={props.data.locationOfInvestments}
          classes={props.data.investorTypes}
          thesis={props.data.mission}
          sectors={props.data.industrySectors}
          verticals={props.data.verticalPreferences}
          frameworks={props.data.frameworks}
        />
      </W>

      <W>
        <Goals
          overarching={props.data.overarchingGoals}
          vertical={props.data.verticalGoals}
        />
      </W>
    </>
  );
}

function W(props) {

  return (
    <div style={{ marginTop: "20px" }}>
      {props.children}
    </div>
  );
}

function FundOverview(props) {
  return (
    <div>
      <Row>
        <Text style={{ fontWeight: 600, fontSize: "16px" }}>Fund Overview</Text>
      </Row>
      <Row style={{ marginTop: "15px" }} gutter={15}>
        <Col span={12}>
          <Thesis
            thesis={props.thesis}
            geographies={props.geographies}
            classes={props.classes}
            sectors={props.sectors}
            verticals={props.verticals}
          />
        </Col>
        <Col span={12}>
          <ReportingFrameworks frameworks={props.frameworks} />
        </Col>
      </Row>
    </div>
  );
}

function Thesis(props) {
  return (
    <div
      style={{
        padding: "10px",
        height: "100%",
        borderStyle: "solid",
        borderColor: "#E5E7EB",
        borderRadius: "7px",
        borderWidth: "1px"
      }}
    >
      <Row>
        <Text
          style={{ fontWeight: 600, fontSize: "12px", marginBottom: "10px" }}
        >
          Investment Thesis
        </Text>
      </Row>
      <Text>{props.thesis}</Text>
      <Row gutter={15}>
        <ThesisTags label="Asset Class" list={props.classes} color="volcano" />
        <ThesisTags
          label="Geographies"
          list={props.geographies}
          color="purple"
        />
        <ThesisTags label="Sectors" list={props.sectors} color="blue" />
        <ThesisTags label="Verticals" list={props.verticals} color="geekblue" />
      </Row>
    </div>
  );
}

function ThesisTags(props) {
  return (
    <Col style={{ marginTop: "15px" }}>
      <Row>
        <Text style={{ fontWeight: 600 }}>{props.label}</Text>
      </Row>
      {props.list.map((item, index) => (
        <Tag
          key={index}
          style={{
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "8px",
            paddingRight: "8px",
            fontWeight: 500,
            marginTop: "8px",
            borderRadius: "15px",
            fontSize: "12px",
          }}
          color={props.color}
        >
          {item}
        </Tag>
      ))}
    </Col>
  );
}

function ReportingFrameworks(props) {
  return (
    <div
      style={{
        padding: "10px",
        height: "100%",
        borderStyle: "solid",
        borderColor: "#E5E7EB",
        borderRadius: "7px",
        borderWidth: "1px"
      }}
    >
      <Row>
        <Text
          style={{ fontWeight: 600, fontSize: "12px", marginBottom: "10px" }}
        >
          Reporting Frameworks
        </Text>
      </Row>
      <Row gutter={15}>
        {props.frameworks.map((framework) => (
          <div key={framework.id} style={{ margin: "8px" }}>
            <Image width="128px" key={framework.name} src={framework.imageUrl} />
          </div>
        ))}
      </Row>
    </div>
  );
}

function augmentGoals(goal) {
  goal.key = goal.id;
  goal.currentValue = parseInt(goal.currentValue);
  goal.targetDate = goal.targetDate.substring(0, 4);
}

function Goals(props) {
  log.debug("GOALS:", props);
  log.debug("OVERARCHING count:", props.overarching.length);
  log.debug("Vertical count:", props.vertical.verticals.length);

  const [showGoals, setShowGoals] = useState(false);

  useEffect(() => {
    if (props.overarching.length > 0 || props.vertical.verticals.length > 0) setShowGoals(true);  
  }, [props.overarching.length, props.vertical.verticals.length]);


  props.overarching.map(augmentGoals);

  props.vertical.verticals.map((vertical) => {
    //log.debug("GOAL:", vertical);
    return vertical.goals.map(augmentGoals);
  });

  return (
    <>
      {!showGoals ? (
        <></>
      ) : (
        <Row gutter={15}>
          <Col span={12}>
            <Row>
              <Text style={{ fontWeight: 600, color: COLOR_DARK_PRIMARY }}>
                Overarching Fund Goals
              </Text>
            </Row>
            <Table
              dataSource={props.overarching}
              columns={goalColumns}
              size="small"
              bordered
            />
          </Col>
          <Col span={12}>
            <Row>
              <Text style={{ fontWeight: 600, color: COLOR_DARK_PRIMARY }}>
                Vertical Specific Goals
              </Text>
            </Row>
            <Tabs
              type="card"
              items={props.vertical.verticals.map((goal, index) => {
                return {
                  label: goal.name,
                  key: index,
                  children: (
                    <Table
                      key={goal.goals.id}
                      dataSource={goal.goals}
                      columns={goalColumns}
                      size="small"
                      bordered
                    />
                  ),
                };
              })}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

interface GoalDataType {
  key: string;
  name: string;
  operator: string;
  currentValue: number;
  target: string;
  targetDate: string;
}

const goalColumns: ColumnsType<GoalDataType> = [
  {
    title: "Metric",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Progress",
    dataIndex: "currentValue",
    key: "currentValue",
    render: (_, record) => (
      <>
        <Progress percent={record.currentValue} />
      </>
    ),
  },
  {
    title: "Goal",
    dataIndex: "target",
    key: "target",
    render: (_, record) => (
      <>
        {record.operator}{record.currentValue}
      </>
    ),
  },
  {
    title: "Target Year",
    dataIndex: "targetDate",
    key: "targetDate",
  },
];

export default FundDetail;
