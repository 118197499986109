
import Page from "@screens/Page";
import Insights from "@components/Insights";

const InsightsPage = () => {


  return (
    <Page siderIndex="2">
      <Insights />
    </Page>
  );
};

export default InsightsPage;
