/**
 * @author  Regimag.in
 * @description  This hook is used fetch static configurations data
 *
 */

import useApiQuery, { ApiQueryProps } from "./useApiQuery";

type CompanyGoalsQueryType = (
  id: string,
  companyId: string,
  options?: ApiQueryProps
) => any;
const useCompanyGoalsQuery: CompanyGoalsQueryType = (
  id: string,
  companyId: string
) => {
  const result = useApiQuery(`/funds/${id}/portfolios/${companyId}/goals`, {
    queryKey: ["dashboard", id, companyId],
    enabled: !!id,
  });
  return result;
};

export default useCompanyGoalsQuery;
