import { GoalsTable } from "@components/Goals/GoalsTable";
import { Typography } from "antd";
import { PortfolioPerformance } from "./PortfolioPerformance";

const { Text } = Typography;

type Props = {
  goal: Map<string, any>;
  fundId: string;
};

export const FundGoal = ({ goal, fundId }: Props) => {

  return (
    <div className="mb-16" key={goal["id"]}>
      <div className="py-4 pr-48">
        <Text style={{ color: "#4B5563" }}>{goal["thesis"].name}</Text>
      </div>

      <GoalsTable goals={goal["goalMetrics"]} />

      <PortfolioPerformance fundId={fundId} goals={goal["goalMetrics"]} />
    </div>
  );
};
