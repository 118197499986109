
import { useParams } from "react-router";

import useFundQuery from "@hooks/api/useFundQuery";
import useActiveFund from "@hooks/useActiveFund";
import log from "loglevel";



const GovernanceDetail = (): JSX.Element => {

  const { activeFundId } = useActiveFund();
  const { id } = useParams();

  
  const { data } = useFundQuery(
    activeFundId,
    (data) => `/funds/${data.id}/portfolios/${id}/governance-performance`,
    {
      queryKey: ["funds", activeFundId, "portfolio", "detail", "governance", "id"],
    }
  );

  log.debug("Governance detail:", data);


 return (<></>);
};


export default GovernanceDetail;
