import { Skeleton, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { ReactComponent as Unavailable } from "@icons/Indicator_unavailable.svg";
import { ReactComponent as Verified } from "@icons/Indicator_verified.svg";
import Icon from "@ant-design/icons";

const { Text } = Typography;

export interface GoalDataType {
  key: string;
  kpi: string;
  target: string;
  isTiedToCompensation: boolean;
  isTiedToGPCompensation: boolean;
  percentageTiedToCompensation: number;
  percentageTiedToGPCompensation: number;
}

const goalColumns: ColumnsType<GoalDataType> = [
  {
    title: <div style={{ fontWeight: 600 }}>KPI</div>,
    dataIndex: "kpi",
    key: "kpi",
    render: (text, record, rowIndex) => {
      return <Text style={{ fontSize: "12px", fontWeight: 600 }}>{text}</Text>;
    },
  },
  {
    title: <div style={{ fontWeight: 600 }}>Target</div>,
    dataIndex: "target",
    key: "target",
  },
  {
    title: <div style={{ fontWeight: 600 }}>Tied to Company Exec. Comp?</div>,
    children: [
      {
        title: <div style={{ fontWeight: 600 }}>Y/N</div>,
        dataIndex: "isTiedToCompensation",
        key: "isTiedToCompensation",
        align: "center",
        width: "100px",
        render: (text, record, rowIndex) => {
          if (text)
            return <Icon style={{ fontSize: "130%" }} component={Verified} />;
          return <Icon style={{ fontSize: "130%" }} component={Unavailable} />;
        },
      },
      {
        title: <div style={{ fontWeight: 600 }}>%</div>,
        align: "center",
        width: "100px",
        dataIndex: "percentageTiedToCompensation",
        key: "percentageTiedToCompensation",
      },
    ],
  },
  {
    title: <div style={{ fontWeight: 600 }}>Tied to GP Comp?</div>,
    children: [
      {
        title: <div style={{ fontWeight: 600 }}>Y/N</div>,
        dataIndex: "isTiedToGPCompensation",
        align: "center",
        width: "100px",
        key: "isTiedToGPCompensation",
        render: (text, record, rowIndex) => {
          if (text)
            return <Icon style={{ fontSize: "130%" }} component={Verified} />;
          return <Icon style={{ fontSize: "130%" }} component={Unavailable} />;
        },
      },
      {
        title: <div style={{ fontWeight: 600 }}>%</div>,
        align: "center",
        width: "100px",
        dataIndex: "percentageTiedToGPCompensation",
        key: "percentageTiedToGPCompensation",
      },
    ],
  },
];

function createGoals(data) {
  const newGoals = [];

  data.forEach((goal: any, key: string) => {
    const target =
      goal.operator === "="
        ? goal.target + goal.unit
        : goal.operator + goal.target + goal.unit;
    const percentageTiedToCompensation =
      goal.percentageTiedToCompensation !== null
        ? goal.percentageTiedToCompensation + "%"
        : "-";
    const percentageTiedToGPCompensation =
      goal.percentageTiedToGPCompensation !== null
        ? goal.percentageTiedToGPCompensation + "%"
        : "-";

    newGoals.push({
      key: goal.id,
      kpi: goal.staticGranularMetricData.displayName,
      target: target,
      isTiedToCompensation: goal.isTiedToCompensation,
      percentageTiedToCompensation: percentageTiedToCompensation,
      isTiedToGPCompensation: goal.isTiedToGPCompensation,
      percentageTiedToGPCompensation: percentageTiedToGPCompensation,
    });
  });

  return newGoals;
}

export const GoalsTable = (props) => {
  const goals = createGoals(props.goals);

  return (
    <>
      {goals ? (
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <Table
            style={{ color: "#4B5563" }}
            dataSource={goals}
            columns={goalColumns}
            size="small"
            bordered={true}
            pagination={false}
          />
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default GoalsTable;
