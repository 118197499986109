import { Skeleton } from "antd";
import Page from "@screens/Page";
import { Typography, Divider } from "antd";
import ReportList from "@components/ReportList"
import useActiveFund from "@hooks/useActiveFund";
import useReportsQuery from "@hooks/api/useReportsQuery";
import log from "loglevel";

const { Text } = Typography;

const Reports = () => {
  const { activeFundId } = useActiveFund();
  const { data: frameworkReports } = useReportsQuery(activeFundId);
  const { data: companyReports } = useReportsQuery(activeFundId, true);

  log.debug("Framework reports:", frameworkReports);
  log.debug("Company reports:", companyReports);

  return (
    <Page siderIndex="4.1">
      <div className='mb-4'>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "left",
            color: "#1F2937",
          }}
        >
          Reports
        </Text>
        <Divider className='h-5' type="vertical"/>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: 500,
            textAlign: "left",
            color: "#489FAA",
          }}
        >
          Fund Level
        </Text>
      </div>
      {frameworkReports ? (
        Object.keys(frameworkReports).map((key) => {
          return (
            <ReportList
              key={key}
              icon="/images/ILPA_file.png"
              title={key}
              reports={frameworkReports[key]}
            />
          );
        })
      ) : (
        <Skeleton />
      )}
    </Page>
  );
};

export default Reports;
