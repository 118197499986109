import {
  ConfigProvider,
  Radio,
  Row,

} from "antd";

import Icon from "@ant-design/icons";
//0CC2CB
//76798B



export default function MetricRadio(props) {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
            borderRadius: 7,
          },
          components: {
            Radio: {
              colorPrimary: "#0CC2CB",
              colorText: "#76798B",
              colorPrimaryHover: "#0CC2CB"
            },
          },
        }}
      >    

        <Radio.Button value={props.value} >
          <Row>
            <Icon
              style={{ fontSize: "130%", marginRight: "4px" }}
              component={props.icon}
            />
            {props.value}
          </Row>
        </Radio.Button>
      </ConfigProvider>
    </>
  );
}
