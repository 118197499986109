import { Layout } from "antd";
import { Summary } from "@components/Summary";
import { Modules } from "@components/Modules";
import { PortfolioTable } from "@components/PortfolioTable";
import  Insights from "@components/Insights";
import { contentStyle } from "../utils";

const { Content } = Layout;

const Dashboard = () => {
  return (
    <Content style={{ ...contentStyle }}>
      <Summary />
      <Modules />
      <PortfolioTable dataSource={[]} />
      <Insights />
    </Content>
  );
};

export default Dashboard;
