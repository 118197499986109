/**
 * MetricPanel renders a responsive panel with 8 metrics of a label and a value.
 */
import { CSSProperties, ComponentType, SVGProps, useCallback } from "react";
import { Card, Col, Row, Grid } from "antd";
import ReimaginCard from "./ReimaginCard";

const { useBreakpoint } = Grid;

type MetricPanelProps = {
  items: {
    label: string;
    value: string;
    icon?: ComponentType<SVGProps<SVGSVGElement>>;
    src?: string;
  }[];
};

const MetricPanel = ({ items = [] }: MetricPanelProps) => {
  const screens = useBreakpoint();
  const responsiveProps = {
    lg: { span: 6 },
    sm: { span: 12 },
    xs: { span: 24 },
  };

  const styleFor = useCallback(
    (row: number, col: number) => {
      const borderRightCss: CSSProperties = {
        borderRightWidth: "1px",
        borderRightStyle: "solid",
        borderColor: "#F0F0F0",
      };
      const borderBottomCss: CSSProperties = {
        borderBottomStyle: "solid",
        borderColor: "#F0F0F0",
        borderBottomWidth: "1px",
      };
      const styleForXs = (row: number, col: number): CSSProperties => {
        const base = { paddingBottom: "20px" };
        if (col === 1 && row === 1) {
          return { ...borderBottomCss, ...base };
        }
        if (col === 4 && row === 2) {
          return { ...base, paddingTop: "20px" };
        }

        return { ...base, ...borderBottomCss, paddingTop: "20px" };
      };
      const styleForSm = (row: number, col: number): CSSProperties => {
        if (row === 1) {
          const row1 = {
            ...borderBottomCss,
            paddingBottom: "20px",
            ...(col % 2 === 0 ? {} : borderRightCss),
          };
          if (col === 1) {
            return {
              ...row1,
            };
          }
          if (col > 2) {
            return {
              ...row1,
              paddingTop: "20px",
            };
          } else {
            return { ...borderBottomCss };
          }
        }
        if (row === 2) {
          const row2 = {
            paddingBottom: "20px",
            paddingTop: "20px",
            ...(col % 2 === 0 ? {} : borderRightCss),
          };

          if (col > 2) {
            return {
              ...row2,
              paddingTop: "20px",
            };
          } else {
            // row 3
            return { ...borderBottomCss, ...row2 };
          }
        }
      };
      const styleForLg = (row: number, col: number): CSSProperties => {
        if (row === 1) {
          if (col === 1 || col === 2 || col === 3) {
            return {
              paddingBottom: "20px",
              ...borderBottomCss,
              ...borderRightCss,
            };
          } else {
            return { ...borderBottomCss };
          }
        }
        if (row === 2) {
          if (col === 1 || col === 2 || col === 3) {
            return {
              paddingTop: "20px",
              ...borderRightCss,
            };
          } else {
            return { paddingTop: "20px" };
          }
        }
        return {};
      };

      if (screens.lg) return styleForLg(row, col);
      if (screens.sm) return styleForSm(row, col);
      if (screens.xs) return styleForXs(row, col);
      return {};
    },
    [screens]
  );

  const row1 = items.slice(0, 4);
  const row2 = items.slice(4, 8);

  return (
    <Card className="w-full" style={{ marginTop: "20px" }}>
      <Row>
        {row1.map((item, index) => {
          return (
            <Col
              key={1 * index}
              {...responsiveProps}
              style={styleFor(1, index + 1)}
            >
              {item && <ReimaginCard {...item} />}
            </Col>
          );
        })}
      </Row>

      <Row>
        {row2.map((item, index) => {
          return (
            <Col
              key={1 * index}
              {...responsiveProps}
              style={styleFor(2, index + 1)}
            >
              {item && <ReimaginCard {...item} />}
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default MetricPanel;
