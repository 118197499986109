// Color variants
export const COLOR_GREEN_SECONDARY = "#0CC2CB";
export const COLOR_GREEN_MID = "#B7DA2C";
export const COLOR_PURPLE_MID = "#8025F3";
export const COLOR_BLUE_MID = "#6B9EFF";
export const COLOR_BLUE_LIGHT = "#CEEDFF";
export const COLOR_BLACK_PRIMARY = "#222222";
export const COLOR_WHITE_PRIMARY = "#ffffff";
export const COLOR_GRAY_PRIMARY = "#777777";
export const COLOR_GRAY_PRIMARY2 = "#76798B";
export const COLOR_GRAY_LIGHT = "#D0D4DC";

// Other colors
export const COLOR_DARK_PRIMARY = "#2D324E";
export const COLOR_BRAND_PRIMARY = "#00A2AC";
export const COLOR_TYPOGRAPHY_MID = "#4B5563";