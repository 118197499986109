import { COLOR_BLUE_LIGHT } from "@constants/colors"

export const PeopleIcon = ({ color=COLOR_BLUE_LIGHT }) => <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="30" height="30" rx="6" fill={color} />
  <path fillRule="evenodd" clipRule="evenodd" d="M16.7165 14.292C16.4225 14.6071 16.0787 14.8727 15.6984 15.0777C15.7979 15.2119 15.9076 15.3392 16.0267 15.4583C16.5049 15.9365 17.1141 16.2621 17.7773 16.394C18.4406 16.5259 19.128 16.4581 19.7528 16.1993C20.3775 15.9404 20.9114 15.5022 21.287 14.9398C21.6626 14.3776 21.863 13.7165 21.8629 13.0404L18.4447 9.62207C18.0729 9.62202 17.7057 9.68258 17.357 9.79954C17.4014 9.88762 17.4427 9.97747 17.4805 10.0689C17.608 10.3765 17.6958 10.6983 17.7426 11.0264C17.9671 10.9483 18.2043 10.9077 18.4445 10.9078H18.4447C18.7247 10.9078 19.002 10.9629 19.2608 11.0701C19.5195 11.1773 19.7546 11.3344 19.9526 11.5324C20.1506 11.7304 20.3077 11.9655 20.4149 12.2243C20.5221 12.483 20.5772 12.7603 20.5772 13.0404V13.0405C20.5773 13.4624 20.4523 13.8748 20.2179 14.2257C19.9835 14.5765 19.6504 14.85 19.2606 15.0115C18.8708 15.173 18.4419 15.2152 18.0281 15.1329C17.6143 15.0507 17.2342 14.8475 16.9359 14.5492C16.8557 14.469 16.7824 14.383 16.7165 14.292ZM19.7528 9.88227C19.3381 9.7105 18.8936 9.62209 18.4448 9.62207L21.8629 13.0402C21.8629 12.5914 21.7745 12.1469 21.6027 11.7322C21.431 11.3175 21.1792 10.9407 20.8617 10.6233C20.5443 10.3058 20.1675 10.0541 19.7528 9.88227Z" fill="#2D324E" />
  <path d="M17.2996 11.8615C17.2997 12.6254 17.0733 13.3722 16.649 14.0075C16.2246 14.6427 15.6214 15.1378 14.9157 15.4302C14.2099 15.7227 13.4333 15.7992 12.6841 15.6502C11.9348 15.5012 11.2466 15.1333 10.7064 14.5932C10.1662 14.053 9.79838 13.3647 9.64937 12.6155C9.50037 11.8662 9.57692 11.0896 9.86932 10.3839C10.1617 9.67812 10.6569 9.07494 11.2921 8.65061C11.9273 8.22628 12.6741 7.99986 13.4381 8C13.9452 8 14.4473 8.09988 14.9158 8.29394C15.3843 8.488 15.81 8.77243 16.1686 9.13101C16.5271 9.48958 16.8116 9.91527 17.0056 10.3838C17.1997 10.8523 17.2996 11.3544 17.2996 11.8615Z" stroke="#2D324E" strokeWidth="1.28571" strokeLinejoin="round" />
  <path d="M18.8187 22.161C18.9032 22.161 18.9454 22.161 18.981 22.1584C19.4573 22.123 19.836 21.7444 19.8713 21.2681C19.874 21.2325 19.874 21.1902 19.874 21.1058V21.1058C19.874 19.5559 16.9923 18.2988 13.4392 18.2988C9.88608 18.2988 7 19.5564 7 21.1058V21.1058" stroke="#2D324E" strokeWidth="1.28571" strokeLinecap="round" strokeLinejoin="round" />
  <path fillRule="evenodd" clipRule="evenodd" d="M18.4459 17.0239C17.1019 17.0239 15.8536 17.2605 14.9173 17.6686C14.581 17.8152 14.2699 17.9904 14.006 18.1965C14.9567 18.2079 15.8573 18.3069 16.6668 18.4758C17.2009 18.3704 17.8031 18.3096 18.4459 18.3096C19.6557 18.3096 20.7206 18.5249 21.459 18.847C21.8291 19.0085 22.0883 19.1842 22.2458 19.3466C22.4004 19.5061 22.4279 19.6192 22.4279 19.6843C22.4279 19.7252 22.4279 19.7479 22.4276 19.7646L22.4274 19.7734C22.4251 19.7866 22.4147 19.797 22.4015 19.7993L22.3927 19.7995C22.376 19.7998 22.3533 19.7998 22.3124 19.7998H19.7449C20.1824 20.1849 20.4283 20.6187 20.4283 21.0775V21.0856H22.3124L22.3292 21.0856H22.3292C22.3883 21.0856 22.4583 21.0857 22.5236 21.0794C23.1504 21.0183 23.6464 20.5223 23.7075 19.8955C23.7138 19.8302 23.7137 19.7602 23.7137 19.7011L23.7137 19.6843C23.7137 19.1923 23.4823 18.7748 23.1688 18.4515C22.8582 18.1313 22.4399 17.8722 21.973 17.6686C21.0374 17.2604 19.7898 17.0239 18.4459 17.0239Z" fill="#2D324E" />
</svg>

export default PeopleIcon
