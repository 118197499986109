export const en = {
  portfolio: {
    people: {
      tabs: {
        executive_team: "Executive team",
      },
    },
    internal_oversight_roles: {
      procurement: "Procurement",
      procument: "Procurement", // typo from api
      esg_team: "ESG team",
    },
  },
};

export default en;
