import { ConfigProvider, Skeleton, Tabs, Typography } from "antd";
import Page from "@screens/Page";

import useActiveFund from "@hooks/useActiveFund";

import { useState, useEffect, useMemo } from "react";
import FilterDropdown from "@components/FilterDropdown";
import useDashboardQuery from "@hooks/api/useDashboardQuery";
import useCompanyGoalsQuery from "@hooks/api/useCompanyGoalsQuery";
import GoalsTable from "@components/Goals/GoalsTable";
import { ReactComponent as OverArchingSelected } from "@icons/record-circle-line-2.svg";
import { KeyItem } from "@components/Goals/PerformanceKeyItem";
import axios from "axios";
import { API_URL } from "@constants/urls";
import useAuth from "@hooks/useAuth";

const { Text } = Typography;

export interface Props {
  label: string;
  children: JSX.Element;
  selectedIcon: JSX.Element;
  unselectedIcon: JSX.Element;
  key: string;
}
interface CompanyCommitmentProps {
  fundId: string;
  companyId: string;
}

const CompanyPerformance = ({ fundId, companyId, goals }) => {
  const { getJwtToken } = useAuth();

  const [metrics, setMetrics] = useState([]);
  const [years, setYears] = useState([]);
  const [keyedData, setKeyedData] = useState({});

  const getMetricPerformanceData = async () => {
    const token = await getJwtToken();

    const requests = goals.map((goal) =>
      axios.get(
        `${API_URL}/funds/${fundId}/goals/performance?metricId=${goal.staticGranularMetricData.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );

    const responses = await Promise.all(requests);
    const updatedMetrics = [];
    let allYears = [];

    responses.forEach((response, index) => {
      // @ts-ignore
      const companyGoal = response.data.filter(
        (item) => item.company.id === companyId
      )[0];
      updatedMetrics.push({
        goal: companyGoal.goalMetric,
        metric: goals[index].staticGranularMetricData,
      });
      Object.keys(companyGoal.goalMetric.years).forEach((year) => {
        if (!allYears.includes(year)) {
          allYears.push(year);
        }
      });
      allYears.sort();
      if (allYears.length === 0) {
        const latestYear = new Date().getFullYear() - 1;
        return [latestYear - 3, latestYear - 2, latestYear - 1, latestYear];
      }
    });
    const updatedKeyedData = {};
    updatedMetrics.forEach((metric) => {
      let mostRecentTimestamp = null;
      let mostRecentValue = "-";
      updatedKeyedData[metric.metric.id] = {
        performance: "-",
      };

      Object.keys(metric.goal.years).forEach((year) => {
        updatedKeyedData[metric.metric.id][year] = {};
        metric.goal.years[year].forEach((quarter) => {
          updatedKeyedData[metric.metric.id][year][Number(quarter.quarter)] =
            quarter.value;

          if (
            mostRecentTimestamp < `${year}${quarter}` ||
            mostRecentTimestamp === null
          ) {
            mostRecentTimestamp = `${year}${quarter}`;
            mostRecentValue = quarter.value;
          }
        });
      });
      updatedKeyedData[metric.metric.id]["performance"] = mostRecentValue;
    });

    setKeyedData(updatedKeyedData);
    setYears(allYears);
    setMetrics(updatedMetrics);
  };

  useEffect(() => {
    getMetricPerformanceData();
  }, [companyId]);

  return (
    <div>
      <table
        style={{
          width: "100%",
        }}
      >
        <thead
          style={{
            backgroundColor: "#F9FAFB",
            height: 72,
            borderBottom: "1px solid #E5E7EB",
          }}
        >
          <tr>
            <th
              style={{
                paddingLeft: 20,
                borderRight: "1px solid #E5E7EB",
              }}
              rowSpan={2}
            >
              KPIs
            </th>

            {years.map((year, index) => (
              <th
                style={{
                  textAlign: "center",
                  borderRight: "1px solid #E5E7EB",
                }}
                colSpan={4}
                key={`${year}-${index}`}
              >
                FY {year}
              </th>
            ))}
            <th
              style={{
                textAlign: "center",
                borderRight: "1px solid #E5E7EB",
              }}
              rowSpan={2}
            >
              Status
            </th>
            <th
              style={{
                paddingLeft: 20,
              }}
              rowSpan={2}
            >
              Performance
            </th>
          </tr>
          <tr>
            {years.map((year) =>
              [1, 2, 3, 4].map((quarter) => (
                <th
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid #E5E7EB",
                    borderTop: "1px solid #E5E7EB",
                  }}
                  key={`${year}-${quarter}`}
                >
                  Q{quarter}
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody>
          {metrics?.map((metric, index) => {
            let goalStatus: "on-track" | "behind" | "delayed";

            if (keyedData[metric.metric.id]["performance"] === "-") {
              goalStatus = "behind";
            }
            // Condition for meeting target
            else if (
              new Function(
                `return ${keyedData[metric.metric.id]["performance"]} ${
                  metric.goal.companyTargetOperator
                } ${metric.goal.companyTarget}`
              )()
            ) {
              goalStatus = "on-track";
            } else {
              if (
                new Date(keyedData[metric.metric.id].companyTargetDate) <
                new Date()
              ) {
                goalStatus = "delayed";
              } else {
                goalStatus = "behind";
              }
            }

            return (
              <tr
                style={{
                  height: 49,
                }}
                key={metric.metric.id}
              >
                <td
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#4B5563",
                    borderRight: "1px solid #E5E7EB",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  {metric.metric.displayName}
                </td>
                {years.map((year) =>
                  [1, 2, 3, 4].map((quarter) => (
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 500,
                        color: "#4B5563",
                        borderRight: "1px solid #E5E7EB",
                        borderBottom: "1px solid #E5E7EB",
                      }}
                      key={`${year}-${quarter}`}
                    >
                      {keyedData[metric.metric.id][year]
                        ? keyedData[metric.metric.id][year][quarter]
                          ? keyedData[metric.metric.id][year][quarter]
                          : "-"
                        : "-"}
                    </td>
                  ))
                )}
                <td
                  style={{
                    borderRight: "1px solid #E5E7EB",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      backgroundColor:
                        goalStatus === "on-track"
                          ? "#BCE127"
                          : goalStatus === "behind"
                          ? "#FFE119"
                          : "#FF6262",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </td>
                <td
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  {/* Performance container */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* TABLE KEY */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          height: 46,
          paddingRight: 10,
        }}
      >
        <KeyItem
          style={{
            marginRight: 16,
          }}
          label="On track"
          color="#BCE127"
          type="circle"
        />
        <KeyItem
          style={{
            marginRight: 16,
          }}
          label="Behind"
          color="#FFE119"
          type="circle"
        />
        <KeyItem
          style={{
            marginRight: 32,
          }}
          label="Delayed"
          color="#FF6262"
          type="circle"
        />

        <KeyItem
          style={{
            marginRight: 16,
          }}
          label="Performance"
          color="#005587"
          type="line"
        />
        <KeyItem label="Company Goal" color="#0CC1CA" type="line" />
      </div>
    </div>
  );
};

const CompanyCommitments = ({
  fundId,
  companyId,
}: CompanyCommitmentProps) => {

  const { data, isLoading } = useCompanyGoalsQuery(fundId, companyId);

console.log("COmpany Goals", data);

  return (
    <div>
      {
        (isLoading) ? <><Skeleton></Skeleton></> :

        data?.map((goal) => (
          <div className="mb-16" key={goal["id"]}>
            <div className="py-4 pr-48">
              <Text style={{ color: "#4B5563" }}>{goal["thesis"].name}</Text>
            </div>

            <GoalsTable goals={goal["goalMetrics"]} />

            <div
              style={{
                marginTop: 40,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  color: "#2D324E",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Company Performance
              </Text>
              <div
                style={{
                  width: "100%",
                  borderRadius: 12,
                  border: "1px solid #E5E7EB",
                  overflow: "hidden",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#F9FAFB",
                    paddingLeft: 16,
                    height: 50,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      color: "#2D324E",
                      fontSize: 12,
                      fontFamily: "Inter",
                      fontWeight: "600",
                      wordWrap: "break-word",
                    }}
                  >
                    Company Commitments
                  </div>
                  <div
                    style={{ width: 140, height: 0, border: "1px #0CC2CB solid" }}
                  ></div>
                </div>
                <CompanyPerformance
                  goals={goal["goalMetrics"]}
                  fundId={fundId}
                  companyId={companyId}
                />
              </div>
            </div>

            {/* <PortfolioPerformance fundId={fundId} goals={goal["goalMetrics"]} /> */}
          </div>
        ))
      }
    </div>
  );
};

const CompanyGoals = () => {
  const { activeFundId } = useActiveFund();
  const { data: portfolioData, isLoading: isLoadingPortfolio } = useDashboardQuery(activeFundId);

  const sanitizedPortfolioData = useMemo(() => {
    return portfolioData?.portfolios.map((item) => ({
      value: item.id,
      label: item.displayName,
    }));
  }, [portfolioData]);

  const [selectedTab, setSelectedTab] = useState("0");
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  return (
    <Page siderIndex="3.2">
      <ConfigProvider
        theme={{
          components: {
            Divider: {
              fontSize: 12,
            },
            Select: {
              fontSize: 12,
            },
            Table: {
              colorPrimary: "#4B5563",
            },
          },
          token: {
            fontSize: 12,
            fontWeightStrong: 500,
            colorPrimary: "#4B5563",
          },
        }}
      >
        <div style={{ color: "#4B5563", fontFamily: "Inter" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 36,
            }}
          >
            <Text style={{ fontSize: "18px", fontWeight: 700 }}>
              Goal Setting
            </Text>
            <div
              style={{
                width: 1,
                height: 26,
                marginLeft: 16,
                marginRight: 16,
                backgroundColor: "#E5E7EB",
              }}
            ></div>
            <Text
              style={{ fontSize: "18px", fontWeight: 700, color: "#00A2AC" }}
            >
              Company Level
            </Text>
          </div>
          <div
            style={{
              borderRadius: 6,
              border: "1px solid #E5E7EB",
              padding: 16,
              backgroundColor: "#F9FAFB",
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: "#2D324E",
                fontWeight: 600,
              }}
            >
              Filter By:
            </Text>
            <FilterDropdown
              isLoading={isLoadingPortfolio}
              label="Company"
              mode="single"
              options={sanitizedPortfolioData}
              onChange={(selected) => {
                setSelectedCompanyId(String(selected));
              }}
            />
          </div>
          <Tabs
            defaultActiveKey={selectedTab}
            onChange={onChange}
            items={[
              {
                label: (
                  <div
                    style={{
                      color: "#00A2AC",
                      fontSize: 12,
                      fontWeight: 600,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 24,
                    }}
                    className="flex items-center gap-2"
                  >
                    <OverArchingSelected />
                    <div>Company Commitments</div>
                  </div>
                ),
                key: "commitments",
                children: (
                  <>
                    {
                      selectedCompanyId === undefined ? <></> :
                      <CompanyCommitments
                        fundId={activeFundId}
                        companyId={selectedCompanyId}
                      />
                    }
                  </>

                ),
              },
            ]}
          />
        </div>
      </ConfigProvider>
    </Page>
  );
};

export default CompanyGoals;
