/**
 * @author  Regimag.in
 * @description  This hook is used to get the current fund id context
 *
 */
import { useContext } from "react";
import StaticConfigContext from "@components/ContextProviders/StaticConfigContext";

function useStaticConfig(): any {
  return useContext(StaticConfigContext);
}

export default useStaticConfig;
