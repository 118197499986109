import { useRouteError } from "react-router-dom";
import Page from "./Page";
import { Alert, Card, ConfigProvider } from "antd";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Page siderIndex="1">

      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
            borderRadius: 5
          },
        }}
      >
        <Card title="Whoops" style={{ width: "100%", margin: "auto", marginTop: 100 }}>
          <p>

          </p>
          <Alert
            message={error.statusText || error.message}
            description="Sorry, an unexpected error has occurred"
            type="error"
          />
        </Card>
      </ConfigProvider>

    </Page>
  );
}