import useConfigurationsQuery from "@hooks/api/useConfigurationsQuery";
import { ReactNode, createContext } from "react";

const StaticConfigContext = createContext(undefined);

type StaticConfigContextProps = {
  children?: ReactNode;
};

type StaticConfigProps = {
  data: any;
  isLoading: boolean;
  findCurrency: (id: string) => any;
};

export const StaticConfigProvider = ({
  children,
}: StaticConfigContextProps) => {
  const { data, isLoading } = useConfigurationsQuery();

  const findCurrency = (id: string) => {
    if (data?.staticCurrencies) {
      return data.staticCurrencies.find(
        (item: { id: string }) => item.id === id
      );
    }
    return null;
  };

  const value: StaticConfigProps = {
    data,
    isLoading,
    findCurrency,
  };

  return (
    <StaticConfigContext.Provider value={value}>
      {children}
    </StaticConfigContext.Provider>
  );
};

export default StaticConfigContext;
