/**
 * @author  Regimag.in
 * @description  This hook is used fetch users/me data
 *
 */
import useAuth from "@hooks/useAuth";
import useApiQuery from "./useApiQuery";
import { UseQueryResult } from "@tanstack/react-query";
export type MeQueryType = {
  data: { profileUrl?: string } & unknown;
};
const useMeQuery = (): UseQueryResult & MeQueryType => {
  const { isSignedIn } = useAuth();

  return useApiQuery(`/users/me`, {
    queryKey: ["me"],
    enabled: isSignedIn,
  });
};

export default useMeQuery;
