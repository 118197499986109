import { Tabs, Typography } from "antd";

import log from "loglevel";
import { useState, useEffect } from "react";
import { StackedGoals } from "@components/Goals/StackedGoals";
import useActiveFund from "@hooks/useActiveFund";

const { Text } = Typography;

type Props = {
  verticals: Map<string, any>;
};

export const Vertical = ({ verticals }: Props) => {
  const [tabs, setTabs] = useState(null);
  const { activeFundId } = useActiveFund();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    log.debug("VERTICAL GOALS:", verticals);

    const newTabs = [];

    for (var vertical in verticals) {
      //log.debug("VERTICAL:", vertical);
      const goals = verticals[vertical];
      newTabs.push({
        key: vertical,
        label: (
          <Text style={{ color: "#2D324E", fontWeight: 500 }}>{vertical}</Text>
        ),
        children: (
          <div className="">
            <div key={vertical} className="m-4">
              <StackedGoals fundId={activeFundId} goals={goals} />
            </div>
          </div>
        ),
      });
    }
    setTabs(newTabs);

    return () => {};
  }, [verticals, activeFundId]);

  return (
    <>
      <Tabs
        style={{ padding: "0px" }}
        type="card"
        items={tabs}
        tabBarStyle={{ color: "red" }}
      />
    </>
  );
};
