import ReimaginHeader from "@components/ReimaginHeader";
import ReimaginSider from "@components/ReimaginSider";
import { Layout } from "antd";
import { contentStyle } from "../utils";

const {  Content } = Layout;

/** 
const footerStyle: CSSProperties = {
  textAlign: "center",
  color: COLOR_BLACK_PRIMARY,
  marginTop: 2,
  backgroundColor: COLOR_WHITE_PRIMARY,
  minHeight: 280
};
*/

const Page = (props) => {
  return (
    <>
      <ReimaginHeader />
      <Content >
        <Layout hasSider={true}>
          <ReimaginSider siderIndex={props.siderIndex} />
          <Content style={contentStyle}>{props.children}</Content>
        </Layout>
      </Content>
      {/**<Footer style={footerStyle}></Footer>*/}
    </>
  );
};

export default Page;
