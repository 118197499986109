import { useParams } from "react-router";
import CalendarChart, { DetailTabs, LoadingBarChart } from "./CalendarChart";
import useFundQuery from "@hooks/api/useFundQuery";
import useActiveFund from "@hooks/useActiveFund";
import EmptyTable from "@components/EmptyTable";
import { formatThousands } from "src/utils";
import { useState, useEffect } from "react";

const EnvironmentalDetail = (): JSX.Element => {
  const { activeFundId } = useActiveFund();
  const { id } = useParams();
  const [period, setPeriod] = useState(0);

  const [carLabel] = useState("Carbon Emissions");
  const [co2Label] = useState("T CO2e");
  const [invLabel] = useState("Investment in Carbon Credits");

  const { data, isLoading } = useFundQuery(
    activeFundId,
    (data) => `/funds/${data.id}/portfolios/${id}/environment-performance`,
    {
      queryKey: [
        "funds",
        activeFundId,
        "portfolio",
        "detail",
        "environment",
        id,
      ],
    }
  );

  useEffect(() => {
    if (!isLoading) {
      setItems([
        {
          label: carLabel,
          key: "0",
          children: (
            <CalendarChart
              label={carLabel}
              period={period}
              data={data.carbonEmissions}
              formatter={formatThousands}
            />
          ),
        },
        { label: co2Label, key: "1", children: <EmptyTable /> },
        { label: invLabel, key: "2", children: <EmptyTable /> },
      ]);
    } else {
      setItems([
        { label: carLabel, key: "0", children: <LoadingBarChart /> },
        { label: co2Label, key: "1", children: <EmptyTable /> },
        { label: invLabel, key: "2", children: <EmptyTable /> },
      ]);
    }
  }, [isLoading, period, invLabel, co2Label, carLabel, data]);

  const [items, setItems] = useState([]);

  return <DetailTabs items={items} setPeriod={setPeriod} />;
};

export default EnvironmentalDetail;
