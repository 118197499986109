import { ConfigProvider, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { formatNumber } from "src/utils";
import { formatDate } from "src/utils/date";

const { Text } = Typography;

export interface PortfolioTableRowDataType {
  key: string;
  id: string;
  company: string;
  icon: string;
  year: number;
  industry: [];
  headcount: number;
  investmentDate: string;
  amtFunded: number;
  revenue: number;
  ebitda: number;
}

function formatList(list) {
  let listAsCommaString = "";

  if (list === null || list === undefined) return listAsCommaString;

  list.forEach(function (item, index) {
    if (index === 0) listAsCommaString = item;
    else listAsCommaString = listAsCommaString + ", " + item;
  });
  return listAsCommaString;
}

const columns: ColumnsType<PortfolioTableRowDataType> = [
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    render: (_, record) => (
      <div className="flex items-center">
        {
          record.icon ?
          <img
            style={{ marginLeft: 8, marginRight: 8, width: 28, borderRadius: 14 }}
            src={record.icon}
            alt={record.company}
          /> :
          <div style={{ marginLeft: 8, marginRight: 8, width: 28, borderRadius: 14 }}></div>
        }

        <Link
          style={{ fontSize: "12px", fontWeight: 600, color: "#4B5563" }}
          to={`/portfolio/details/${record.id}`}
        >
          {record.company}
        </Link>
      </div>
    ),
  },
  {
    title: "Investment Date",
    dataIndex: "investmentDate",
    key: "investmentDate",
    responsive: ["lg"],
    render: (_, record) => formatDate(new Date(record.investmentDate)),
  },
  {
    title: "Year Founded",
    dataIndex: "year",
    key: "year",
    sorter: (a, b) => a.year - b.year,
  },
  {
    title: "Industry",
    dataIndex: "industry",
    key: "industry",
    render: (_, record) => formatList(record.industry),
  },
  {
    title: "Headcount",
    dataIndex: "headcount",
    key: "headcount",
    responsive: ["md"],
    render: (_, record) => formatNumber(record.headcount),
    sorter: (a, b) => a.headcount - b.headcount,
  },

  {
    title: "Amt. Funded ($)",
    dataIndex: "amtFunded",
    key: "amtFunded",
    responsive: ["lg"],
    render: (_, record) => formatNumber(record.amtFunded),
    sorter: (a, b) => a.amtFunded - b.amtFunded,
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    key: "revenue",
    responsive: ["lg"],
    render: (_, record) => formatNumber(record.revenue),
    sorter: (a, b) => a.revenue - b.revenue,
  },
  {
    title: "EBITDA($)",
    dataIndex: "ebitda",
    key: "ebitda",
    responsive: ["lg"],
    render: (_, record) => formatNumber(record.ebitda),
    sorter: (a, b) => a.ebitda - b.ebitda,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    responsive: ["lg"],
    render: (_, record) => "ACTIVE",
    sorter: (a, b) => a.ebitda - b.ebitda,
  },
];

type PortfolioTableProps = {
  dataSource: PortfolioTableRowDataType[];
};
export const PortfolioTable = ({ dataSource }: PortfolioTableProps) => {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
        }}
      >
        <div
          style={{ textAlign: "left", marginBottom: "20px", marginTop: "20px" }}
        >
          <Text
            style={{
              textAlign: "left",
              color: "#2D324E",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Portfolio
          </Text>
          <div
            style={{
              marginTop: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "lightgray",
              borderRadius: "5px",
            }}
          >
            <Table
              style={{ fontSize: "16px", fontWeight: 500, color: "#4B5563" }}
              dataSource={dataSource}
              columns={columns}
              bordered={false}
              pagination={false}
            />
          </div>
        </div>
      </ConfigProvider>
    </>
  );
};
