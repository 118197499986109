import { Empty, Skeleton } from "antd";
import Page from "@screens/Page";
import { Typography, Image, Divider } from "antd";
import ReportList from "@components/ReportList"
import useActiveFund from "@hooks/useActiveFund";
import useReportsQuery from "@hooks/api/useReportsQuery";
import log from "loglevel";
import { useState, useEffect, useMemo } from "react";

const { Text } = Typography;

const type2Image = {
  "B Impact": "/images/B_impact_file.png",
  "SDGs": "/images/SDG_file.png",
  "TCFD": "/images/TCFD_File_Icon.svg",
  "GRI": "/images/GRI_file.png",
  "Financial Statements": "/images/Finance_File.png",
  "ILPA Convergance": "/images/ILPA_file.png",
  "Novata": "/images/Novata_File_Icon.png",
  "SFDR": "/images/SFDR_File_Icon.png"
}

const Reports = () => {
  const { activeFundId } = useActiveFund();
  const { data: frameworkReports } = useReportsQuery(activeFundId);
  const { data: companyReports } = useReportsQuery(activeFundId, true);

  const [reports, setReports] = useState([]);

  log.debug("Framework reports:", frameworkReports);
  log.debug("Company reports:", companyReports);

  useEffect(() => {
    if (companyReports) {

      const newReports = [];

      companyReports.sort(sortByName).forEach((company) => {
        console.log("Foo:", company);
        newReports.push(<CompanyReports company={company} />)
      })  

      setReports(newReports)

    }
  }, [companyReports]);

  function sortByName(company1, company2) {
    if ( company1.company.displayName < company2.company.displayName ){
      return -1;
    }
    if ( company1.company.displayName > company2.company.displayName){
      return 1;
    }
    return 0;
  }
////        companyReports.sort(sortByName).forEach((company) => {


  return (
    <Page siderIndex="4.2">
      <div className='mb-4'>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "left",
            color: "#1F2937",
          }}
        >
          Reports
        </Text>
        <Divider className='h-5' type="vertical"/>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: 500,
            textAlign: "left",
            color: "#489FAA",
          }}
        >
          Company Level
        </Text>
      </div>

      {
        companyReports ? 
          reports
        : 
          <Skeleton />
      }
    </Page>
  );
};

export function CompanyReports(props) {

  const companyDetails = props.company.company;
  const reports = props.company.reports;

  log.debug("companyDetails", companyDetails);
  log.debug("reports", reports);
  
  return (
    <div
      style={{
        padding: "0px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "lightgray",
        borderRadius: "15px",
        marginBottom: "25px"
      }}
    >
      <div style={{ margin: "8px" }}>
        <div className="flex items-center" style={{ padding: "20px" }}>
          <Image width="32px" preview={false} src={companyDetails.profileUrl} />
          <Text
            style={{ marginLeft: "10px", fontSize: "16px", fontWeight: 600 }}
          >
            {companyDetails.displayName}
          </Text>
        </div>

        {
        reports ? 
          Object.keys(reports).length > 0 ?
            Object.keys(reports).map((key, index) => {
              return <ReportList
                        key={index}
                        icon={type2Image[key]}
                        title={key}
                        reports={reports[key]}
                      />;
            })
            : <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="There aren't any company level reports"
            />
            : <Skeleton />
          
        }
      </div>
    </div>
  );
}



export default Reports;
