import { Skeleton } from "antd";
import log from "loglevel";
import { useState, useEffect } from "react";

import { FundGoal } from "@components/Goals/FundGoal";

type Props = {
  fundId: string;
  goals: Map<string, any>;
};

export const StackedGoals = ({ goals, fundId }: Props) => {
  const [fundGoals, setFundGoals] = useState(null);

  useEffect(() => {
    log.debug("Stacked GOALS:", goals);

    const newFundGoals = [];

    goals?.forEach((goal, key) => {
      newFundGoals.push(
        <div key={key}>
          <FundGoal fundId={fundId} goal={goal} />
        </div>
      );
    });

    setFundGoals(newFundGoals);
  }, [goals, fundId]);

  return <>{fundGoals ? <>{fundGoals}</> : <Skeleton />}</>;
};
