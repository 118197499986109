import {
  Col,
  Row,
  Skeleton,
  Typography

} from "antd";

import { CompanyReports } from "@screens/CompanyReports";
import useActiveFund from "@hooks/useActiveFund";
import useReportsQuery from "@hooks/api/useReportsQuery";

const { Text } = Typography;

const Reports = ({ company }) => {
  const { activeFundId } = useActiveFund();
  const { data: companyReports, isLoading } = useReportsQuery(
    activeFundId,
    true,
    [company]
  );

  return (
    <div style={{marginLeft:"10px", marginTop:"30px"}} id="reports">

      <Row>
        <Col span={24}>
          <div style={{marginBottom: "15px"}}>
            <Text style={{ paddingBottom: "20px", fontSize: "16px" }}>Reports</Text>
          </div>
          {
            isLoading || companyReports === undefined ? <Skeleton /> : <CompanyReports company={companyReports[0]} index={0} />
          }
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
