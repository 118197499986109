import {
  Avatar,
  Card,
  Col,
  ConfigProvider,
  Empty,
  Grid,
  Image,
  List,
  Popover,
  Row,
  Skeleton,
  Space,
  Statistic,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ArrowLeftOutlined,
  EnvironmentOutlined,
  MailOutlined,
} from "@ant-design/icons";

import Page from "@screens/Page";
import {
  COLOR_BRAND_PRIMARY,
  COLOR_GRAY_LIGHT,
  COLOR_GRAY_PRIMARY,
  COLOR_DARK_PRIMARY,
} from "@constants/colors";
import { formatMillions, formatNumber, splitArray } from "src/utils";
import CalendarIcon from "@components/Icons/Calendar";
import PeopleIcon from "@components/Icons/PeopleIcon";
import EbitdaIcon from "@components/Icons/EbitdaIcon";
import RevenueIcon from "@components/Icons/RevenueIcon";
import { TabbedPeopleTable } from "@components/PeopleTable";
import FilterDropdown from "@components/FilterDropdown";
import { ReactComponent as Unavailable } from "@icons/Indicator_unavailable.svg";
import { ReactComponent as Verified } from "@icons/Indicator_verified.svg";

import { ReactComponent as CommitmentsActive } from "@icons/portfolio/Commitments-active.svg";
import { ReactComponent as CommitmentsDefault } from "@icons/portfolio/Commitments-default.svg";
import { ReactComponent as GovernanceActive } from "@icons/portfolio/Governance-active.svg";
import { ReactComponent as GovernanceDefault } from "@icons/portfolio/Governance-default.svg";
import { ReactComponent as PeopleActive } from "@icons/portfolio/People-active.svg";
import { ReactComponent as PeopleDefault } from "@icons/portfolio/People-default.svg";
import { ReactComponent as PerformanceActive } from "@icons/portfolio/Performance-active.svg";
import { ReactComponent as PerformanceDefault } from "@icons/portfolio/Performance-default.svg";
import { ReactComponent as OverviewActive } from "@icons/portfolio/Overview-active.svg";
import { ReactComponent as OverviewDefault } from "@icons/portfolio/Overview-default.svg";
import { ReactComponent as ReportsActive } from "@icons/portfolio/Reports-active.svg";
import { ReactComponent as ReportsDefault } from "@icons/portfolio/Reports-default.svg";

import { ScrollingTabs } from "@components/ScrollingTabs";
import RoleCard from "@components/RoleCard";
import { useParams } from "react-router";
import useFundQuery from "@hooks/api/useFundQuery";
import useActiveFund from "@hooks/useActiveFund";
import useStaticConfig from "@hooks/useStaticConfig";
import { CurrencyDataType } from "src/types/currency";
import useConfigurationsQuery from "@hooks/api/useConfigurationsQuery";
import { Link } from "react-router-dom";
import GrowthDetail from "./GrowthDetail";
import GovernanceDetail from "./GovernanceDetail";
import EnvironmentalDetail from "./EnvironmentalDetail";
import Reports from "./Reports";

import log from "loglevel";

const { useBreakpoint } = Grid;

const { Text, Title } = Typography;

type StatsCardProps = {
  stats: {
    title: string;
    value: string | number;
    icon: ReactNode;
    formatter?: (value) => ReactNode;
  }[];
};

const StatsCard = ({ stats }: StatsCardProps) => {
  return (
    <Card style={{ marginBottom: "30px" }}>
      <Row>
        {stats.map((stat, index) => {
          return (
            <Col span={12} key={index}>
              <Statistic
                valueStyle={{
                  fontWeight: "700",
                  fontSize: "18px",
                  color: COLOR_DARK_PRIMARY,
                  lineHeight: "32px",
                  alignContent: "center",
                  display: "flex",
                }}
                key={index}
                title={stat.title}
                value={stat.value}
                formatter={stat.formatter}
                prefix={stat.icon}
                style={{ margin: "10px 0" }}
              />
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

type CompanyResult = {
  name: string;
  profileUrl: string;
  industry: string;
  totalEmployees: number;
  investmentDate: string;
  totalInvested: number;
  totalRevenue: number;
  totalEbitda: number;
  yearFounded: number;
  mission: string;
  description: string;
  website: string;
  address: string;
  verticalAlignments: string[];
  ownershipDemographics: string[];
  sdgAlignments: { title?: string; src: string }[];
  membersByRole: any;
  membersByTeam: any;
  performance: { categories: any[] };
  commitments: { goals: any[] };
  financialStatements: { title: string; url: string }[];
  bImpactStatements: { title: string; url: string }[];
  gRIStatements: { title: string; url: string }[];
};

const preparePortfolioCompany = (data): CompanyResult => {
  const { performance, commitments, ...rest } = data;
  return {
    ...rest,
    yearFounded: new Date(data.dateOfIncorporation).getFullYear(),
  };
};

const Detail = () => {
  const { activeFundId } = useActiveFund();
  const { id } = useParams();
  const [currency, setCurrency] = useState<CurrencyDataType>();
  const { data: configData, findCurrency } = useStaticConfig();
  const { data, isLoading } = useFundQuery(
    activeFundId,
    (data) => `/funds/${data.id}/portfolios/${id}`,
    {
      queryKey: ["funds", activeFundId, "portfolio", id],
      transform: preparePortfolioCompany,
    }
  );
  const [tabs, setTabs] = useState([
    { DefaultIcon: <OverviewDefault />, 
      ActiveIcon: <OverviewActive />, 
      label: "Portfolio Overview", 
      child: <OverviewTab company={data} headlineStats={[]} isLoading={isLoading}/>},

    { DefaultIcon: <PerformanceDefault />, ActiveIcon: <PerformanceActive />, label: "Performance", child: <OperationsTab />},
    { DefaultIcon: <CommitmentsDefault />, ActiveIcon: <CommitmentsActive />, label: "Commitments", child: <CommitmentsTab /> },
    { DefaultIcon: <PeopleDefault />, ActiveIcon: <PeopleActive />, label: "People", child: <PeopleTab company={data} /> },
    { DefaultIcon: <GovernanceDefault />, ActiveIcon: <GovernanceActive />, label: "Governance", child: <GovernanceTab company={data} /> },
    { DefaultIcon: <ReportsDefault />, ActiveIcon: <ReportsActive />, label: "Reports", child: <Reports company={id} /> },
]);

  log.debug("Portfolio detail:", data);

  useEffect(() => {
    if (data && configData) {
      const curr = findCurrency(data.currency);
      setCurrency(curr);
    }
  }, [data, configData, findCurrency]);

  useEffect(() => {
    if (data) {
      log.debug("Portfolio Detail:", data);

      const stats = [
        {
          title: "Year founded",
          value: data.yearFounded.toString(),
          icon: <CalendarIcon />,
          formatter: (value) => value,
        },
        {
          title: "Headcount",
          value: data.totalEmployees,
          icon: <PeopleIcon />,
          formatter: formatNumber,
        },
        {
          title: "Revenue",
          value: data.totalRevenue,
          icon: <RevenueIcon />,
          formatter: (value) => formatMillions(value, { currency }),
        },
        {
          title: "EBITDA",
          value: data.totalEbitda,
          icon: <EbitdaIcon />,
          formatter: (value) => formatMillions(value, { currency }),
        },
      ];

      setTabs([
        {
          DefaultIcon: <OverviewDefault />,
          ActiveIcon: <OverviewActive />,
          label: "Portfolio Overview",
          child: (
            <OverviewTab
              company={data}
              headlineStats={stats}
              isLoading={isLoading}
            />
          ),
        },

        {
          DefaultIcon: <PerformanceDefault />,
          ActiveIcon: <PerformanceActive />,
          label: "Performance",
          child: <OperationsTab />,
        },
        {
          DefaultIcon: <CommitmentsDefault />,
          ActiveIcon: <CommitmentsActive />,
          label: "Commitments",
          child: <CommitmentsTab />,
        },
        {
          DefaultIcon: <PeopleDefault />,
          ActiveIcon: <PeopleActive />,
          label: "People",
          child: <PeopleTab company={data} />,
        },
        {
          DefaultIcon: <GovernanceDefault />,
          ActiveIcon: <GovernanceActive />,
          label: "Governance",
          child: <GovernanceTab company={data} />,
        },
        {
          DefaultIcon: <ReportsDefault />,
          ActiveIcon: <ReportsActive />,
          label: "Reports",
          child: <Reports company={id} />,
        },
      ]);
    }
  }, [data, currency, isLoading]);

  return (
    <Page siderIndex="1">
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              colorPrimary: COLOR_BRAND_PRIMARY,
              colorPrimaryHover: COLOR_BRAND_PRIMARY,
            },
            Button: {
              colorPrimary: COLOR_BRAND_PRIMARY,
              colorPrimaryHover: COLOR_BRAND_PRIMARY,
            },
          },
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
        }}
      >
        <div style={{ paddingLeft: "10px" }}>
          <Link to={`/portfolio/`} title="Back" style={{ marginRight: "10px" }}>
            <ArrowLeftOutlined />
          </Link>
          <Text
            style={{
              fontSize: "18px",
              fontWeight: 700,
              textAlign: "left",
              color: COLOR_DARK_PRIMARY,
              paddingRight: "10px",
              borderRightColor: COLOR_GRAY_LIGHT,
              borderRightStyle: "solid",
              borderRightWidth: "1px",
            }}
          >
            Portfolio
          </Text>
          <Text
            style={{
              paddingLeft: "10px",
              fontSize: "18px",
              fontWeight: 700,
              textAlign: "left",
              color: COLOR_BRAND_PRIMARY,
            }}
          >
            {data?.displayName}
          </Text>
        </div>

        <ScrollingTabs items={tabs} />
      </ConfigProvider>
    </Page>
  );
};

const OperationsTab = () => {
  return (
    <div
      style={{ marginLeft: "10px", marginTop: "30px", marginBottom: "20px" }}
      id="performance"
    >
      <Row>
        <Col span={24}>
          <div style={{ marginBottom: "15px" }}>
            <Text
              style={{
                paddingBottom: "20px",
                fontSize: "16px",
                color: "#2D324E",
              }}
            >
              Performance
            </Text>
          </div>
          <div style={{ borderWidth: "0px", borderRadius: "12px" }}>
            <Tabs
              tabBarStyle={{
                marginBottom: "0px",
                paddingLeft: "10px",
                backgroundColor: "#F9FAFB",
                color: "#76798B",
                fontWeight: 600,
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                borderBottomWidth: "0px",
                borderWidth: "1px",
              }}
              style={{ backgroundColor: "white" }}
              items={[
                {
                  label: "Growth",
                  key: "growth",
                  children: <GrowthDetail />,
                },
                {
                  label: "Environment",
                  key: "environmental",
                  children: <EnvironmentalDetail />,
                },
                {
                  label: "Governance",
                  key: "governance",
                  children: <GovernanceDetail />,
                },
              ]}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const CommitmentsTab = () => (
  <div
    style={{ marginLeft: "10px", marginTop: "30px", marginBottom: "30px" }}
    id="commitments"
  >
    <Row>
      <Col span={24}>
        <div style={{ marginBottom: "15px" }}>
          <Text style={{ paddingBottom: "20px", fontSize: "16px" }}>
            Commitments
          </Text>
        </div>

        <Card
          headStyle={{
            fontSize: 12,
            fontWeight: 500,
            borderBottom: 0,
            marginBottom: 0,
          }}
          extra={
            <FilterDropdown
              label="Filter by date"
              isLoading={false}
              options={[
                {
                  value: "2010",
                  label: "2010",
                },
                {
                  value: "2011",
                  label: "2011",
                },
                {
                  value: "2012",
                  label: "2012",
                },
                {
                  value: "2013",
                  label: "2013",
                },
                {
                  value: "2014",
                  label: "2014",
                },
                {
                  value: "2015",
                  label: "2015",
                },
              ]}
            />
          }
        >
          <Tabs
            style={{ height: "500px" }}
            type="card"
            defaultActiveKey="1"
            items={[
              { label: "Eliminating Products that use Virgin Materials" },
              { label: "Reduce Carbon Footprint in Supply Chain" },
              { label: "Maintaining 1% Giving" },
              { label: "Drive DE&I within the Organization" },
            ].map(({ label }, i) => {
              return {
                label,
                key: label.toLowerCase(),
                children: (
                  <>{i === 0 && <Empty description={"Data unavailable"} />}</>
                ),
              };
            })}
          />
        </Card>
      </Col>
    </Row>
  </div>
);

const RoleCardRow = ({ roles, index }) => {
  const [role1, role2] = roles;
  //log.debug("Role1:", role1);
  //log.debug("Role2:", role2);

  const { sm } = useBreakpoint();
  const children = (
    <>
      <Col
        span={24}
        sm={{ span: 12 }}
        style={{ paddingRight: sm ? "20px" : 0 }}
      >
        <RoleCard
          role={role1}
          icon={
            role1.members && role1.members.length > 0 ? (
              <Verified />
            ) : (
              <Unavailable />
            )
          }
        />
      </Col>
      <Col span={24} sm={{ span: 12 }} style={{ marginTop: sm ? 0 : "20px" }}>
        {role2 ? (
          <RoleCard
            role={role2}
            icon={
              role2.members && role2.members.length > 0 ? (
                <Verified />
              ) : (
                <Unavailable />
              )
            }
          />
        ) : (
          <></>
        )}
      </Col>
    </>
  );

  return <Row style={{ marginBottom: "20px" }}>{children}</Row>;
};

const GovernanceTab = ({ company }) => {
  const [items, setItems] = useState([]);
  const [roles, setRoles] = useState();
  const { t } = useTranslation();

  const { data: configData, isLoading } = useConfigurationsQuery();

  //log.debug("Roles:", roles);
  useEffect(() => {
    if (company?.governance) {
      const shaped = company.governance.map((item) => {
        return {
          key: item.id,
          title: item.displayName,
          icon: item.value ? <Verified /> : <Unavailable />,
        };
      });
      setItems(shaped);
    }
  }, [company?.governance]);

  useEffect(() => {
    if (company?.membersByRoles && configData?.staticRoles) {
      const shaped = configData.staticRoles.map((role: any) => {
        return {
          key: role.id,
          title: t(
            `portfolio.internal_oversight_roles.${role.name.toLowerCase()}`,
            {
              defaultValue: role.displayName,
            }
          ),

          members: (company.membersByRoles[role.name] || []).map((member) => {
            return {
              key: member.id,
              name: member.name,
              src: member.profileUrl,
            };
          }),
        };
      });
      setRoles(shaped);
    }
  }, [company?.membersByRoles, configData, t]);

  return (
    <div
      style={{ marginLeft: "10px", marginTop: "30px", marginBottom: "30px" }}
      id="governance"
    >
      <Row>
        <Col span={24}>
          <div style={{ marginBottom: "15px" }}>
            <Text style={{ paddingBottom: "20px", fontSize: "16px" }}>
              Governance
            </Text>
          </div>

          <Row style={{ marginTop: "20px" }}>
            <Col span={24} md={{ span: 12 }} style={{ paddingRight: "20px" }}>
              <Card
                title={
                  <Text className="text-xs font-semibold">
                    Corporate Policies and Procedures
                  </Text>
                }
                headStyle={{ borderBottom: 0 }}
              >
                <List
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={items}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={item.icon}
                        description={
                          <Text className="text-xs font-medium">
                            {item.title}
                          </Text>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <Card
                title={
                  <Text className="text-xs font-semibold">
                    Internal Oversight Roles
                  </Text>
                }
                headStyle={{ borderBottom: 0 }}
              >
                {isLoading || !roles ? (
                  <Skeleton avatar active paragraph={{ rows: 4 }} />
                ) : (
                  splitArray(roles, 2).map((roleRows, index) => {
                    return (
                      <RoleCardRow key={index} roles={roleRows} index={index} />
                    );
                  })
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const OverviewTab = ({ company, headlineStats, isLoading }) => {
  return (
    <div
      style={{ marginLeft: "10px", marginTop: "10px", marginBottom: "20px" }}
      id="overview"
    >
      <Card loading={isLoading}>
        {isLoading ? (
          <></>
        ) : (
          <Row>
            <Col sm={{ span: 24 }} lg={{ span: 12 }}>
              <Row>
                <Col span={3} className="min-w-[72px]">
                  <Avatar size={64} shape="square" src={company.profileUrl} />
                </Col>

                <Col span={18}>
                  <Text style={{ fontSize: "22px", fontWeight: "500" }}>
                    {company?.displayName}
                  </Text>
                  <p>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: COLOR_GRAY_PRIMARY,
                      }}
                    >
                      {company?.vision}
                    </Text>
                  </p>
                  <div>
                    <EnvironmentOutlined style={{ marginRight: "10px" }} />
                    <Text style={{ fontSize: 12, fontWeight: 500 }}>
                      {company.address}
                    </Text>
                  </div>

                  {company.email === null ? (
                    <div></div>
                  ) : (
                    <div>
                      <MailOutlined style={{ marginRight: "10px" }} />
                      <Text style={{ fontSize: 12, fontWeight: 500 }}>
                        {company.email}
                      </Text>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24} className="mt-4">
                  <div style={{ marginRight: "10px" }}>
                    <Title level={5}>Mission Statement</Title>
                    <Text style={{ fontWeight: 300, fontSize: "12px" }}>
                      {company?.mission}
                    </Text>
                  </div>
                </Col>
              </Row>

              <Row gutter={18} style={{ paddingTop: "0px" }}>
                <DetailTags
                  title="Industry"
                  color="red"
                  tags={company.industries}
                  useName={false}
                />
                <DetailTags
                  title="Verticals"
                  color="blue"
                  tags={company.verticalAlignments}
                  useName={true}
                />
                <DetailTags
                  title="Ownership"
                  color="purple"
                  tags={company.ownershipDemographics}
                  useName={false}
                />
              </Row>
            </Col>
            <Col sm={{ span: 24 }} lg={{ span: 12 }}>
              {headlineStats && headlineStats.length > 0 ? (
                <StatsCard stats={headlineStats} />
              ) : (
                <Skeleton active avatar paragraph={{ rows: 4 }} />
              )}
              <SDGCard sdgs={company.sdgAlignments} />
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
};

type DetailTagsProps = {
  tags: any[];
  title: string;
  color: string;
  useName: boolean;
};

const DetailTags = ({ tags, title, color, useName }: DetailTagsProps) => {
  return (
    <Col>
      <div
        style={{
          marginTop: "18px",
          marginBottom: "18px",
        }}
      >
        <Text
          style={{
            fontSize: "12px",
            fontWeight: "500",
            color: COLOR_GRAY_PRIMARY,
          }}
        >
          {title}
        </Text>
        <div>
          <Space size={[0, 8]} wrap>
            {tags.map((item, i) => {
              return (
                <Tag
                  key={i}
                  style={{
                    padding: "6px",
                    fontWeight: 500,
                    marginTop: "8px",
                    borderRadius: "20px",
                    fontSize: "12px",
                  }}
                  color={color}
                >
                  {useName ? item.name : item}
                </Tag>
              );
            })}
          </Space>
        </div>
      </div>
    </Col>
  );
};

type SDGCardProps = {
  sdgs: any[];
};

const SDGCard = ({ sdgs }: SDGCardProps) => {
  return (
    <Card headStyle={{ border: 0 }} title="SDG Alignment">
      {sdgs &&
        sdgs.map((sdg, i) => {
          return (
            <Space key={i} style={{ marginRight: "12px" }}>
              <Popover content={sdg.number} title={sdg.name}>
                <div>
                  <Image
                    preview={false}
                    key={i}
                    src={sdg.imageURL}
                    width={42}
                    height={42}
                  />
                </div>
              </Popover>
            </Space>
          );
        })}
    </Card>
  );
};

const PeopleTab = ({ company }) => {
  const { t } = useTranslation();
  const [tabData, setTabData] = useState<any[]>([]);

  useEffect(() => {
    if (company) {
      const { membersByTeam } = company;
      const data = Object.keys(membersByTeam).map((key) => {
        return {
          label: t(key.toLowerCase()),
          tableProps: { list: membersByTeam[key] },
        };
      });
      setTabData(data);
    }
  }, [company, t]);

  return (
    <div
      style={{ marginLeft: "10px", marginTop: "30px", marginBottom: "30px" }}
      id="people"
    >
      <Row>
        <Col span={24}>
          <div style={{ marginBottom: "15px" }}>
            <Text style={{ paddingBottom: "20px", fontSize: "16px" }}>
              People
            </Text>
          </div>

          <Card style={{ marginTop: "20px" }}>
            {tabData && tabData.length > 0 ? (
              <TabbedPeopleTable tabs={tabData} />
            ) : (
              <Empty description="People data is unavailable" />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Detail;
