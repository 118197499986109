import { ConfigProvider, Skeleton, Tabs, Typography } from "antd";
import Page from "@screens/Page";
import useGoalsQuery from "@hooks/api/useGoalsQuery";

import useActiveFund from "@hooks/useActiveFund";
import { StackedGoals } from "@components/Goals/StackedGoals";
import { Vertical } from "@components/Goals/Vertical";
import { ReactComponent as VerticalSelected  } from "@icons/record-circle-fill.svg";
import { ReactComponent as VerticalUnselected } from "@icons/record-circle-fill-2.svg";
import { ReactComponent as OverArchingSelected } from "@icons/record-circle-line.svg";
import { ReactComponent as OverArchingUnselected } from "@icons/record-circle-line-2.svg";
import { useState, useEffect } from "react";
import log from "loglevel";

const { Text } = Typography;

export interface Props {
  label: string;
  children: JSX.Element;
  selectedIcon: JSX.Element;
  unselectedIcon: JSX.Element;
  key: string;
}

const FundGoals = () => {
  const { activeFundId } = useActiveFund();
  const { data, isLoading } = useGoalsQuery(activeFundId);
  const [selectedTab, setSelectedTab] = useState("0");
  const [tabs, setTabs] = useState<Props[]>([]);
  useEffect(() => {
    if (!isLoading) {
      log.debug("GOALS:", data);

      setTabs([
        {
          label: `Overarching Fund Commitments`,
          children: (
            <StackedGoals
              fundId={activeFundId}
              goals={data.data.overarchingGoals}
            />
          ),
          selectedIcon: <OverArchingSelected />,
          unselectedIcon: <OverArchingUnselected />,
          key: "0",
        },
        {
          label: `Vertical Specific Commitments`,
          children: <Vertical verticals={data.data.verticalSpecificGoals} />,
          selectedIcon: <VerticalSelected />,
          unselectedIcon: <VerticalUnselected />,
          key: "1",
        },
      ]);
    }
  }, [isLoading, activeFundId, data]);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  return (
    <Page siderIndex="3.1">
      <ConfigProvider
        theme={{
          components: {
            Divider: {
              fontSize: 12,
            },
            Select: {
              fontSize: 12,
            },
            Table: {
              colorPrimary: "#4B5563",
            },
          },
          token: {
            fontSize: 12,
            fontWeightStrong: 500,
            colorPrimary: "#4B5563",
          },
        }}
      >
        <div style={{ color: "#4B5563", fontFamily: "Inter" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 36,
            }}
          >
            <Text style={{ fontSize: "18px", fontWeight: 700 }}>
              Goal Setting
            </Text>
            <div
              style={{
                width: 1,
                height: 26,
                marginLeft: 16,
                marginRight: 16,
                backgroundColor: "#E5E7EB",
              }}
            ></div>
            <Text
              style={{ fontSize: "18px", fontWeight: 700, color: "#00A2AC" }}
            >
              Fund Level
            </Text>
          </div>
          {isLoading ? (
            <Skeleton className="mt-16" active={isLoading} />
          ) : (
            <div>
              <Tabs
                defaultActiveKey={selectedTab}
                onChange={onChange}
                items={tabs.map((item, index) => {
                  return {
                    label: (
                      <div key={index} className="flex items-center gap-2">
                        {item.key === selectedTab
                          ? item.selectedIcon
                          : item.unselectedIcon}
                        {item.label}
                      </div>
                    ),
                    key: item.key,
                    children: item.children,
                  };
                })}
              />
            </div>
          )}
        </div>
      </ConfigProvider>
    </Page>
  );
};

export default FundGoals;
