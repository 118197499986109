import Icon from "@ant-design/icons";
import { Col, Image, Row } from "antd";
import { ComponentType, SVGProps } from "react";

type ReimaginCardProps = {
  label: string;
  value: string;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  src?: string;
};
const ReimaginCard = (props: ReimaginCardProps) => {
  return (
    <Row align="middle" style={{ marginLeft: "20px" }}>
      <Col span={4}>
        {props.src ? (
          <Image preview={false} height="48px" width="48px" src={props.src} />
        ) : (
          <Icon style={{ fontSize: "350%" }} component={props.icon} />
        )}
      </Col>
      <Col span={1} />
      <Col span={19} style={{ textAlign: "left", paddingLeft: "20px" }}>
        <Row>
          <span
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: 700,
              color: "#2D324E",
            }}
          >
            {props.value}
          </span>
        </Row>
        <Row>
          <span
            style={{ textAlign: "left", fontWeight: 500, color: "#4B5563" }}
          >
            {props.label}
          </span>
        </Row>
      </Col>
    </Row>
  );
};

export default ReimaginCard;
