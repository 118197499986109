import { Card, Col, ConfigProvider, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Page from "@screens/Page";
import {
  PortfolioTable,
  PortfolioTableRowDataType,
} from "@components/PortfolioTable";
import MetricPanel from "@components/Dashboards/MetricPanel";
import FilterDropdown from "@components/FilterDropdown";
import useFundQuery from "@hooks/api/useFundQuery";
import useActiveFund from "@hooks/useActiveFund";

import { ReactComponent as percentageIcon } from "@icons/percentage.svg";
import { ReactComponent as co2LowIcon } from "@icons/co2-lo.svg";
import { ReactComponent as co2HighIcon } from "@icons/co2-hi.svg";
import { ReactComponent as genderlessRedIcon } from "@icons/genderless-red.svg";
import { ReactComponent as genderlessBlueIcon } from "@icons/genderless-blue.svg";
import { ReactComponent as buildingLineIcon1 } from "@icons/building-line-blue1.svg";
import { ReactComponent as buildingLineIcon2 } from "@icons/building-line-blue2.svg";
import { ReactComponent as esop } from "@icons/blue_dollar.svg";
import log from "loglevel";

const ACTIVE = "ACTIVE";
const EXITED = "EXITED";
const INVESTMENT_TYPES = [
  { label: ACTIVE, value: ACTIVE },
  { label: EXITED, value: EXITED },
];

type FilterBarProps = {
  values?: {
    investmentYears?: string[];
    locations?: string[];
    investmentStatus?: string[];
    industries?: string[];
  };
  loadedFilterData: boolean;
  staticYears: { label: string; value: string }[];
  staticLocations: { label: string; value: string }[];
  staticIndustries: { label: string; value: string }[];
  onFilterLocation: (value: string[]) => void;
  onFilterInvestmentYears: (value: string[]) => void;
  onFilterIndustries: (value: string[]) => void;
  onFilterStatus: (value: string[]) => void;
};

const FilterBar = ({
  values: { locations, investmentYears, investmentStatus, industries },
  loadedFilterData,
  staticYears,
  staticLocations,
  staticIndustries,
  onFilterLocation,
  onFilterInvestmentYears,
  onFilterIndustries,
  onFilterStatus,
}: FilterBarProps) => {
  /*
  log.debug("staticYears:", staticYears);
  log.debug("staticLocations:", staticLocations);
  log.debug("staticIndustries:", staticIndustries);
*/

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Card: {
              colorBgContainer: "#F9FAFB",
              colorBorder: "#E5E7EB",
            },
          },
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
        }}
      >
        <Card
          headStyle={{ border: 0 }}
          style={{ textAlign: "left" }}
          title="Filter Portfolio By:"
          bodyStyle={{ paddingTop: "10px" }}
        >
          <Row>
            <Col
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <FilterDropdown
                isLoading={!loadedFilterData}
                onChange={onFilterInvestmentYears}
                label="Investment Year"
                options={staticYears}
                values={investmentYears}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <FilterDropdown
                isLoading={!loadedFilterData}
                values={locations}
                label="Location"
                options={staticLocations}
                onChange={onFilterLocation}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <FilterDropdown
                isLoading={!loadedFilterData}
                values={industries}
                label="Industry"
                options={staticIndustries}
                onChange={onFilterIndustries}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0" }}
            >
              <FilterDropdown
                isLoading={false}
                values={investmentStatus}
                label="Investment Status"
                options={INVESTMENT_TYPES}
                onChange={onFilterStatus}
              />
            </Col>
          </Row>
        </Card>
      </ConfigProvider>
    </>
  );
};

const labels = {
  highestAttrition: "Highest Attrition",
  highestEmissions: "Highest Emissions",
  lestDiverseWorkForce: "Least Diverse Workforce",
  lowestAttrition: "Lowest Attrition",
  lowestEmission: "Lowest Emissions",
  mostDiverseWorkforce: "Most Diverse Workforce",
  percentagePayingLivingWage: "Percentage Paying Living Wage",
  percentagePortfolioWithEsopProgram: "ESOP program",
};

const MetricIcons = {
  highestAttrition: buildingLineIcon2,
  highestEmissions: co2HighIcon,
  lestDiverseWorkForce: genderlessRedIcon,
  lowestAttrition: buildingLineIcon1,
  lowestEmission: co2LowIcon,
  mostDiverseWorkforce: genderlessBlueIcon,
  percentagePayingLivingWage: percentageIcon,
  percentagePortfolioWithEsopProgram: esop,
};

export const portfolioRowTransform = (item: any, key: number) => {
  return {
    key,
    id: item.id,
    company: item.displayName,
    icon: item.profileUrl,
    year: new Date(item.dateOfIncorporation).getFullYear(),
    industry: item.industries,
    headcount: item.totalEmployees,
    investmentDate: item.investmentDate,
    amtFunded: item.totalInvested,
    revenue: item.totalRevenue,
    ebitda: item.totalEbitda,
  };
};

export const portfolioDataTransform = (data: any) => {
  const table = data.data.map(portfolioRowTransform);
  return { ...data, table };
};

function format(number) {
  number = Math.round(number);
  return number.toLocaleString("en-US");
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

function filterAndSort(oldArray) {
  let newArray: { label: string; value: string }[] = [];
  oldArray = oldArray.filter(onlyUnique).sort();

  oldArray.forEach(function (item, index) {
    newArray.push({ value: item, label: item });
  });

  return newArray;
}

function processIndustries(newArray, industryList) {
  industryList.forEach(function (item, index) {
    newArray.push(item);
  });
}

export const Portfolio = () => {
  const [items, setItems] = useState<any[]>([]);
  const { activeFundId } = useActiveFund();
  const [tableData, setTableData] = useState<PortfolioTableRowDataType[]>([]);

  let [searchParams, setSearchParams] = useSearchParams();
  const queryYears = searchParams.get("y")?.split(",") || [];
  const queryStatus = searchParams.get("s")?.split(",") || [];
  const queryLocations = searchParams.get("l")?.split(",") || [];
  const queryIndustries = searchParams.get("i")?.split(",") || [];
  const defaultStatus = queryStatus.length > 0 ? queryStatus : [ACTIVE];

  const [investmentYears, setInvestmentYears] = useState<string[]>(queryYears);
  const [investmentStatus, setInvestmentStatus] =
    useState<string[]>(defaultStatus);
  const [locations, setLocations] = useState<string[]>(queryLocations);
  const [industries, setIndustries] = useState<string[]>(queryIndustries);
  const [queryParams, setQueryParams] = useState({});

  const [staticInvestmentYears, setStaticInvestmentYears] = useState<
    { label: string; value: string }[]
  >([]);
  const [staticLocations, setStaticLocations] = useState<
    { label: string; value: string }[]
  >([]);
  const [staticIndustries, setStaticIndustries] = useState<
    { label: string; value: string }[]
  >([]);

  const enabled = !!activeFundId;

  // Ruse cache query if no params
  const queryParamsAsKey = (params: any) => params;
  const params =
    Object.keys(queryParams).length === 0
      ? "ALL"
      : queryParamsAsKey(queryParams);

  const { data, isLoading }: any = useFundQuery(activeFundId, "portfolios", {
    queryParams: queryParams,
    queryKey: ["portfolios", activeFundId, params],
    transform: portfolioDataTransform,
    enabled,
  });

  const { data: staticData, isLoading: staticIsLoading }: any = useFundQuery(
    activeFundId,
    "portfolios",
    {
      queryKey: ["portfolios", activeFundId, "ALL"],
      transform: portfolioDataTransform,
      enabled,
    }
  );

  useEffect(() => {
    let params = {};
    if (locations.length > 0)
      params["countryOfIncorporation"] = locations.join(",");
    if (industries.length > 0) params["industries"] = industries.join(",");
    if (investmentYears.length > 0)
      params["investmentYears"] = investmentYears.join(",");

    if (investmentStatus.length > 1)
      params["investmentStatus"] = investmentStatus.join(",");
    else if (investmentStatus.length === 1 && investmentStatus[0] === EXITED)
      params["investmentStatus"] = investmentStatus.join(",");

    setQueryParams(params);
  }, [investmentYears, investmentStatus, locations, industries]);

  useEffect(() => {
    if (staticData && !staticIsLoading) {
      log.debug("static data arrived:", staticData);

      const siy = [];
      const sl = [];
      const si = [];
      staticData.data.forEach(function (item, index) {
        siy.push(new Date(item.investmentDate).getFullYear().toString());
        sl.push(item.countryOfIncorporation);
        processIndustries(si, item.industries);
      });
      setStaticLocations(filterAndSort(sl));
      setStaticInvestmentYears(filterAndSort(siy));
      setStaticIndustries(filterAndSort(si));
    }
  }, [staticIsLoading, staticData]);

  useEffect(() => {
    if (data && enabled) {
      log.debug("data arrived:", data);

      const table = data.data.map(portfolioRowTransform);
      setTableData(table);
      const shaped = Object.keys(data.metadata).reduce((acc, key) => {
        const value = data.metadata[key];
        //log.debug("value:", value);
        let renderedValue = "n/a";
        if (value === null) {
        } else if (value instanceof Object) {
          renderedValue = format(value["value"]);
        } else {
          renderedValue = value;
        }

        return [
          ...acc,
          {
            label: labels[key],
            value: renderedValue, //data.metadata[key]?.name || data.metadata[key],
            icon: MetricIcons[key],
          },
        ];
      }, []);
      setItems(shaped);
    } else {
      setItems([]);
      setTableData([]);
    }
  }, [data, enabled]);

  function updateSearchParams(key, values) {
    const params = {};
    if (industries.length > 0) params["i"] = industries.join(",");
    if (locations.length > 0) params["l"] = locations.join(",");
    if (investmentYears.length > 0) params["y"] = investmentYears.join(",");
    if (investmentStatus.length > 0) params["s"] = investmentStatus.join(",");

    if (values.length > 0) params[key] = values.join(",");
    else delete params[key];
    setSearchParams(params);
  }

  return (
    <Page siderIndex="1">
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
          },
        }}
      >
        <FilterBar
          values={{ investmentYears, investmentStatus, locations, industries }}
          loadedFilterData={!staticIsLoading}
          staticYears={staticInvestmentYears}
          staticLocations={staticLocations}
          staticIndustries={staticIndustries}
          onFilterInvestmentYears={(v) => {
            const sortedValues = v.sort();
            setInvestmentYears(sortedValues);
            updateSearchParams("y", sortedValues);
          }}
          onFilterLocation={(v) => {
            const sortedValues = v.sort();
            setLocations(sortedValues);
            updateSearchParams("l", sortedValues);
          }}
          onFilterIndustries={(v) => {
            const sortedValues = v.sort();
            setIndustries(sortedValues);
            updateSearchParams("i", sortedValues);
          }}
          onFilterStatus={(v) => {
            const sortedValues = v.sort();
            setInvestmentStatus(sortedValues);
            updateSearchParams("s", sortedValues);
          }}
        />

        {isLoading && enabled ? (
          <Skeleton
            style={{ marginTop: "12px" }}
            avatar
            active
            paragraph={{ rows: 4 }}
          />
        ) : (
          <MetricPanel items={items} />
        )}
        <PortfolioTable dataSource={tableData} />
      </ConfigProvider>
    </Page>
  );
};

export default Portfolio;
