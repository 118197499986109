/**
 * @author  Regimag.in
 * @description  This hook is used to create a jwt token authenticated api client
 *
 */

import { useContext } from "react";
import TokenAuthContext from "@components/ContextProviders/TokenAuthContext";

function useApiClient() {
  const { apiClient } = useContext(TokenAuthContext);

  return apiClient;
}

export default useApiClient;
