import {
  Card,
  ConfigProvider,
  Typography,
  Row,
  Divider,
  Badge,
  Button,
  Skeleton,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { ReactComponent as AUMIcon } from "@icons/aum.svg";
import { ReactComponent as PCIcon } from "@icons/pc.svg";
import { ReactComponent as IVIcon } from "@icons/iv.svg";
import { ReactComponent as IGIcon } from "@icons/ig.svg";
import { ReactComponent as ATIPIcon } from "@icons/atip.svg";
import { ReactComponent as TFGIcon } from "@icons/trg.svg";
import { ReactComponent as ARIcon } from "@icons/ar.svg";
import { ReactComponent as THIcon } from "@icons/th.svg";

import useDashboardQuery from "@hooks/api/useDashboardQuery";
import { ResponsivePie } from "@nivo/pie";
import useAuth from "@hooks/useAuth";
import MetricPanel from "@components/Dashboards/MetricPanel";
import log from "loglevel";
import useActiveFund from "@hooks/useActiveFund";
import { useState, useRef, useEffect } from "react";
import { formatName } from "src/utils/string";
import { formatDate } from "src/utils/date";

const { Text } = Typography;

export const Summary = () => {
  const { user } = useAuth();
  const { activeFundId } = useActiveFund();
  const { data, isLoading } = useDashboardQuery(activeFundId);
  const [summaryData, setSummaryData] = useState([]);

  const today = new Date();
  const welcome = "Welcome to your dashboard";

  log.debug("SUMMARY DATA: ", summaryData);

  useEffect(() => {
    if (!isLoading) {
      log.debug("DASHBOARD DATA: ", data);

      setSummaryData([
        {
          label: "AUM",
          value: data?.summary?.aum,
          icon: AUMIcon,
        },
        {
          label: "Investment Geographies",
          value: data?.summary?.investmentGeographies,
          icon: IGIcon,
        },
        {
          label: "Portfolio Companies",
          value: data?.summary?.portfolioCount,
          icon: PCIcon,
        },
        {
          label: "Ave. Tenure In Portfolio",
          value: data?.summary?.avgTenureInPortfolio,
          icon: ATIPIcon,
        },
        {
          label: "Total Headcount",
          value: data?.summary?.totalHeadcount,
          icon: THIcon,
        },
        {
          label: "Total Revenue Growth",
          value: data?.summary?.totalRevenueGrowth,
          icon: TFGIcon,
        },
        {
          label: "Industry Verticals",
          value: data?.summary?.industryVertical,
          icon: IVIcon,
        },
        {
          label: "Ave. Revenue Growth",
          value: data?.summary?.percentageRevenueGrowth,
          icon: ARIcon,
        },
      ]);
    }
  }, [isLoading, data]);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
            borderRadius: 5,
          },
        }}
      >
        {isLoading || !data?.summary ? (
          <Skeleton active={isLoading} />
        ) : (
          <div>
            <Row style={{ margin: "0px", padding: "0px" }}>
              <Text
                style={{ color: "#1F2937", fontSize: "18px", fontWeight: 700 }}
              >
                Hello {formatName(user)}!
              </Text>
            </Row>
            <Row
              style={{
                alignItems: "center",
              }}
            >
              <Text style={{ color: "#4B5563", fontWeight: 500 }}>
                {welcome}
              </Text>
              <div
                style={{
                  backgroundColor: "#D9D9D9",
                  width: 5,
                  height: 5,
                  borderRadius: 2.5,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              ></div>
              <Text style={{ color: "#4B5563", fontWeight: 500 }}>
                {formatDate(today)}
              </Text>
            </Row>

            <Row style={{ marginBottom: 20 }}>
              <MetricPanel items={summaryData} />
            </Row>
            <div style={{ marginBottom: 40 }} className="overflow-x-auto">
              {data.widgets.length === 2 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                  {data.widgets.map((widget, index) => {
                    return (
                      <OverviewCard key={index} data={widget} stretch={true} />
                    );
                  })}
                </div>
              ) : (
                <div className="scrolling-wrapper">
                  {data.widgets.map((widget, index) => {
                    return (
                      <div key={index} className="card max-w-lg mr-4">
                        <OverviewCard
                          key={index}
                          data={widget}
                          stretch={false}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </ConfigProvider>
    </>
  );
};

function OverviewCard(props) {
  //log.debug("Overview Card:", props.data);
  const scrollContainerRef = useRef(null);
  const datumContainerRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(-1);
  const [leftVisible, setLeftVisible] = useState("hidden p-0 m-0");
  const [rightVisible, setRightVisible] = useState("hidden p-0 m-0");
  const [datums, setDatums] = useState([]);

  const clazzName = props.stretch ? "flex justify-between" : "flex";

  function doScrollLeft() {
    scrollContainerRef.current.scrollTo(scrollLeft - 20, 0);
  }

  function doScrollRight() {
    scrollContainerRef.current.scrollTo(scrollLeft + 20, 0);
  }

  useEffect(() => {
    if (props.data.data && datums.length < 1) {
      const nodes = props.data.data.reduce((acc: any, datum, index: number) => {
        const datumNode = (
          <Datum
            key={`data-${index}`}
            data={datum}
            color={selectColor(index)}
          />
        );

        if (index + 1 < props.data.data.length) {
          return [
            ...acc,
            datumNode,
            <Divider
              key={`divider-${index}`}
              style={{ height: "40px" }}
              type="vertical"
            />,
          ];
        } else {
          return [...acc, datumNode];
        }
      }, []);
      setDatums(nodes);
    }
    return () => {};
  }, [props.data.data, datums]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollLeftValue = scrollContainerRef.current.scrollLeft;
      setScrollLeft(scrollContainerRef.current.scrollLeft);
      setScrollRight(
        scrollContainerRef.current.scrollWidth -
          (scrollContainerRef.current.offsetWidth + scrollLeftValue)
      );
    };

    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setScrollRight(
      scrollContainerRef.current.scrollWidth -
        scrollContainerRef.current.offsetWidth
    );

    if (scrollLeft === 0) setLeftVisible("hidden p-0 m-0");
    else setLeftVisible("p-0 m-0");

    if (scrollRight <= 0 || scrollLeft > scrollRight)
      setRightVisible("hidden p-0 m-0");
    else setRightVisible("p-0 m-0");

    return () => {};
  }, [scrollLeft, scrollRight]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          borderRadius: 5,
        },
      }}
    >
      <Card
        bodyStyle={{ marginLeft: "10px", paddingLeft: "0px" }}
        title={props.data.title}
        className="widget-card"
      >
        <div style={{ alignItems: "center" }} className="grid grid-cols-4 pl-4">
          <div className="grid auto-rows-min">
            <Text
              style={{ color: "#9CA3AF", fontSize: "12px", fontWeight: 500 }}
              disabled
              strong
            >
              {props.data.totalLabel}
            </Text>
            <Text
              style={{ fontSize: "14px", fontWeight: 700, color: "#1F2937" }}
            >
              {props.data.totalValue}
            </Text>
          </div>
          <div style={{ height: "90px" }}>
            <ReimaginPie data={props.data.data} />
          </div>
        </div>

        <div className="flex justify-between gap-2">
          <div className="grid content-end">
            {" "}
            <Button onClick={doScrollLeft} type="link" className={leftVisible}>
              <LeftOutlined />
            </Button>
          </div>

          <div
            ref={scrollContainerRef}
            className="scrolling-wrapper mt-6 flex-auto col-span-[16]"
          >
            <div ref={datumContainerRef} className={clazzName}>
              {datums}
            </div>
          </div>

          <div className="grid content-end">
            {" "}
            <Button
              onClick={doScrollRight}
              type="link"
              className={rightVisible}
            >
              <RightOutlined />
            </Button>
          </div>
        </div>
      </Card>
    </ConfigProvider>
  );
}

function selectColor(number) {
  const hue = number * 137.508; // use golden angle approximation
  return `hsl(${hue},50%,75%)`;
}

//style={{backgroundColor:'red'}}

function Datum(props) {
  //log.debug("Datum:", props.data);

  return (
    <>
      <div className="">
        <div className="flex justify-center">
          <Badge style={{ marginRight: "5px" }} color={props.color} />
          <span
            style={{ color: "#6B7280", fontSize: "12px", fontWeight: "500" }}
          >
            {props.data.key}
          </span>
        </div>
        <Row align="middle" justify="center">
          <span
            style={{ color: "#1F2937", fontSize: "12px", fontWeight: "600" }}
          >
            {props.data.unit}
            {props.data.value}
            {props.data.postfix}
          </span>
        </Row>
      </div>
    </>
  );
}

function ReimaginPie(props) {
  for (let index = 0; index < props.data.length; index++) {
    props.data[index].id = props.data[index].key;
    const color = selectColor(index);
    props.data[index].color = color;
  }

  //log.debug("PIE:", props.data);

  return (
    <ResponsivePie
      data={props.data}
      colors={{ datum: "data.color" }}
      margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
      innerRadius={0.65}
      padAngle={0.7}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      enableArcLabels={false}
      enableArcLinkLabels={false}
    />
  );
}
