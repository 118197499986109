interface KeyItemProps {
  label: string;
  color: string;
  type: "circle" | "line";
  style?: object;
}
export const KeyItem = ({ label, color, type, style = {} }: KeyItemProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...style,
      }}
    >
      <div
        style={{
          width: type === "circle" ? 10 : 2,
          height: type === "circle" ? 10 : 13,
          borderRadius: type === "circle" ? 5 : 0,
          marginRight: 6,
          backgroundColor: color,
        }}
      ></div>
      <div
        style={{
          fontFamily: "Inter",
          fontSize: 12,
          fontWeight: 500,
          color: "#2D324E",
        }}
      >
        {label}
      </div>
    </div>
  );
};
