import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import useAuth from "@hooks/useAuth";

import log from "loglevel";
import Axios from "axios";
import { API_URL } from "@constants/urls";

const TokenAuthContext = createContext(undefined);

type TokenAuthContextProps = {
  children?: ReactNode;
};

const axios = Axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const decorateHttpClient = (requestCb, responseCb, responseErrorCb) => {
  log.debug("Instantiate Axios Http Client instance");

  axios.interceptors.request.use(requestCb);

  axios.interceptors.response.use(responseCb, responseErrorCb);
};
export const TokenAuthProvider = ({ children }: TokenAuthContextProps) => {
  const { getJwtToken, signOut, isSignedIn } = useAuth();
  const [token, setToken] = useState<string | null>(null);
  const [ready, setReady] = useState(false);
  const fetchToken = useCallback(async () => {
    const token = await getJwtToken();
    //console.log("token: ", token);
    setToken(token);
    return token;
  }, [getJwtToken, setToken]);

  useEffect(() => {
    if (!token && isSignedIn) fetchToken();
  }, [token, isSignedIn, fetchToken]);

  useEffect(() => {
    if (token) {
      const refresh = async (): Promise<string> => {
        const refreshed = await getJwtToken();
        setToken(refreshed);
        return refreshed;
      };
      axios.interceptors.request.clear();
      axios.interceptors.response.clear();
      /*
      axios.interceptors.request.use(request => {
        console.log('Starting Request', JSON.stringify(request, null, 2))
        return request
      })*/

      decorateHttpClient(
        async (config) => {
          //log.debug("useApiClient, configuring token");

          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
        },
        (response) => {
          //log.debug("useApiClient", response.data);
          return response.data;
        },
        async (error) => {
          log.error("useApiClient error 1:", error);
          if (error.response) {
            if (error.response.status === 401) {
              // Unauthorized - the token could have expired. Try to refresh it.
              try {
                const originalRequest = error.config;
                const token = await refresh();

                originalRequest.headers = {
                  Authorization: `Bearer ${token}`,
                };
                return await axios(originalRequest);
              } catch (error2) {
                log.error("useApiClient error 2:", error2);

                // If the refresh failed, sign out.
                isSignedIn && signOut();
              }
            }
            return Promise.reject({
              ...error.response.data.error,
              status: error.response.status,
            });
          } else if (error.request) {
            return Promise.reject(error.request);
          }
          return Promise.reject(error);
        }
      );
      setReady(true);
    }
  }, [token, signOut, isSignedIn, fetchToken, getJwtToken, ready, setReady]);

  const value = {
    token,
    fetchToken,
    apiClient: axios,
    apiReady: ready,
  };

  return (
    <TokenAuthContext.Provider value={value}>
      {children}
    </TokenAuthContext.Provider>
  );
};

export default TokenAuthContext;
