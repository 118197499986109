const CalendarIcon = ({ color = '#E8CAFF' }) => (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="30" height="30" rx="6" fill={color} />
  <path d="M8.5 11C8.5 9.89543 9.39543 9 10.5 9H21.5C22.6046 9 23.5 9.89543 23.5 11V22C23.5 23.1046 22.6046 24 21.5 24H10.5C9.39543 24 8.5 23.1046 8.5 22V11Z" stroke="#2D324E" strokeWidth="1.2" />
  <path d="M8.5 13.5H23.5" stroke="#2D324E" strokeWidth="1.2" strokeLinecap="round" />
  <path d="M12.5001 7.5L12.5001 10.5" stroke="#2D324E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M19.4999 7.5V10.5" stroke="#2D324E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M16.8999 16.9C16.8999 17.3971 16.4969 17.8 15.9999 17.8C15.5028 17.8 15.0999 17.3971 15.0999 16.9C15.0999 16.4029 15.5028 16 15.9999 16C16.4969 16 16.8999 16.4029 16.8999 16.9Z" fill="#2D324E" />
  <path d="M16.9 20.5001C16.9 20.9972 16.4971 21.4001 16 21.4001C15.5029 21.4001 15.1 20.9972 15.1 20.5001C15.1 20.003 15.5029 19.6001 16 19.6001C16.4971 19.6001 16.9 20.003 16.9 20.5001Z" fill="#2D324E" />
  <path d="M20.4999 16.9C20.4999 17.3971 20.0969 17.8 19.5999 17.8C19.1028 17.8 18.6999 17.3971 18.6999 16.9C18.6999 16.4029 19.1028 16 19.5999 16C20.0969 16 20.4999 16.4029 20.4999 16.9Z" fill="#2D324E" />
  <path d="M13.2999 16.9C13.2999 17.3971 12.897 17.8 12.3999 17.8C11.9028 17.8 11.4999 17.3971 11.4999 16.9C11.4999 16.4029 11.9028 16 12.3999 16C12.897 16 13.2999 16.4029 13.2999 16.9Z" fill="#2D324E" />
  <path d="M13.2999 20.5001C13.2999 20.9972 12.897 21.4001 12.3999 21.4001C11.9028 21.4001 11.4999 20.9972 11.4999 20.5001C11.4999 20.003 11.9028 19.6001 12.3999 19.6001C12.897 19.6001 13.2999 20.003 13.2999 20.5001Z" fill="#2D324E" />
</svg>)

export default CalendarIcon
