/**
 * @author  Regimag.in
 * @description  This hook is used fetch fund data specific to the fund id
 *
 */

import useApiQuery, { ApiQueryProps } from "./useApiQuery";

type FundQueryType = (
  id: string,
  metric: string,
  options?: ApiQueryProps
) => any;
const useGoalsPerformanceQuery: FundQueryType = (
  id: string,
  metric: string,
  options = {}
) => {
  const endpoint = `/funds/${id}/goals/performance`;
  return useApiQuery(endpoint, {
    queryKey: ["funds", id, "goalsperformance", metric],
    queryParams: {"metricId" : metric},
    enabled: !!id,
    ...options,
  });
};

export default useGoalsPerformanceQuery;
