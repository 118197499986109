import { ConfigProvider, Typography, Row, Col, Space, Divider } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as Arrow } from "@icons/arrow-right-line.svg";

import { Pie, ResponsivePie } from '@nivo/pie'

const { Text } = Typography;

export const Modules = () => {

  const responsiveProps = {
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 6 },
  }
  return (
    <div style={{ textAlign: "left", marginBottom: "20px", marginTop: '20px' }} >
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
            borderRadius: 5
          },
        }}
      >
        <Text style={{ textAlign: "left", color: '#2D324E', fontSize: '16px', fontWeight: 600 }} >
          Modules
        </Text>
        <Row gutter={17} align="stretch" style={{ marginTop: '10px' }}>
          <Col {...responsiveProps}>
            <ModuleCard title="Set Goals"
              percentage={25}
              text="Setting business goals and objectives is essential to commercial and sustainble success." />
          </Col>
          <Col {...responsiveProps} >
            <ModuleCard title="Use the SDGs"
              percentage={57}
              text="Measure your commitments against the UN Sustainable Development Goals." />
          </Col>
          <Col {...responsiveProps}>
            <ModuleCard title="Use Third Party Reports"
              percentage={75}
              text="Add any third-party frameworks that you’re already using." />
          </Col>
          <Col {...responsiveProps}>
            <ModuleCard title="Build Custom Dashboards"
              percentage={80}
              text="Let us know what your reporting needs are." />
          </Col>
        </Row>

      </ConfigProvider>
    </div>
  );
};

function ModuleCard(props) {

  return (

    <div style={{ textAlign: "left", height: "100%", boxShadow: "2px 2px 13px rgb(0 0 0 / 0.1)" }} >
      <div style={{ padding: '10px', height: "100%", borderStyle: 'solid', borderColor: '#E5E7EB', borderRadius: '5px' }}>
        <Row>
          <Col span={20} >
            <Text style={{ fontWeight: "600", fontSize: '12px', color: '#2D324E' }}>{props.title}</Text>
            <p style={{ fontWeight: "500", fontSize: '12px', color: '#4B5563' }}>{props.text}</p>
          </Col>
          <Col span={1}>
            <Divider style={{ height: "95%", margin: "0px" }} type="vertical" />
          </Col>
          <Col span={3} >
            <Space>
              <ReimaginPie percentage={props.percentage} height={34} width={34} />
            </Space>
            <Icon style={{ fontSize: "150%", marginTop: "30px", marginLeft: "8px" }} component={Arrow} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

type ReimaginPieProps = {
  percentage: number;
  height?: number;
  width?: number;
}
function ReimaginPie(props: ReimaginPieProps) {

  const data =
    [
      {
        id: "complete",
        value: props.percentage,
        color: "#489FAA"
      },
      {
        id: "outstanding",
        value: 100 - props.percentage,
        color: "#E5E7EB"
      }
    ]

  const { height, width } = props
  const sizeProps = { width, height }

  if (height && width) {
    return (
        <Pie
          data={data}
          colors={({ id, data }) => data.color}
          margin={{ top: 10, right: 30, bottom: 10, left: 30 }}
          innerRadius={0.75}
          padAngle={0.7}
          cornerRadius={0}
          activeOuterRadiusOffset={4}
          activeInnerRadiusOffset={1}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          {...sizeProps}
        />

    )
  }
  return (
    <div style={{ width: '32px', height: '32px' }}>
      <ResponsivePie
        data={data}
        colors={({ id, data }) => data.color}
        margin={{ top: 10, right: 30, bottom: 10, left: 30 }}
        innerRadius={0.75}
        padAngle={0.5}
        cornerRadius={0}
        activeOuterRadiusOffset={4}
        activeInnerRadiusOffset={2}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableArcLabels={false}
        enableArcLinkLabels={false}
      />
    </div>
  );
};