/* 
  This file is used to wrap the app with the context providers
*/

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./AuthContext";
import { TokenAuthProvider } from "./TokenAuthContext";
import { StaticConfigProvider } from "./StaticConfigContext";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/config";

const queryClient = new QueryClient();
console.log(i18n);
const ContextProviders = ({ children }) => (
  <I18nextProvider i18n={i18n} defaultNS={"dictionary"}>
    <AuthProvider>
      <TokenAuthProvider>
        <QueryClientProvider client={queryClient}>
          <StaticConfigProvider>
            {children}
          </StaticConfigProvider>
        </QueryClientProvider>
      </TokenAuthProvider>
    </AuthProvider>
  </I18nextProvider>
);

export default ContextProviders;
