/**
 * @author  Regimag.in
 * @description  This hook is used fetch fund data specific to the fund id
 *
 */

import useApiQuery, { ApiQueryProps } from "./useApiQuery";



/**
 * 
https://43g9b4iew3.execute-api.us-east-1.amazonaws.com/funds/b9e69239-b806-43e5-a040-e3ff1aaf58e7/reports
https://43g9b4iew3.execute-api.us-east-1.amazonaws.com/funds/b9e69239-b806-43e5-a040-e3ff1aaf58e7/company-static-reports
 */

type ReportsQueryType = (
  fundId: string,
  isCompany?: boolean,
  companyIDs?: string[],
  options?: ApiQueryProps
) => any;
const useReportsQuery: ReportsQueryType = (
  fundId: string,
  isCompany: false,
  companyIDs: [],
  options = {}
) => {
  const endpoint = isCompany ? `/funds/${fundId}/company-reports` : `/funds/${fundId}/reports`;

  if (companyIDs === undefined) companyIDs = [];
  const idKeys = companyIDs.length > 0 ? companyIDs.sort().join(",") : "";

  const queryParams = companyIDs.length > 0 ? {companyIds: idKeys} : {};
  
  return useApiQuery(endpoint, {

    queryKey: ["reports", fundId, isCompany, idKeys].filter((s) => s),
    queryParams: queryParams,
    enabled: !!fundId,
    ...options,
  });
};

export default useReportsQuery;
