import { useEffect, useState } from "react";
import { PersonDataType } from "src/types/people";
import { ReactComponent as LinkedIn } from "@icons/linkedin.svg";
import { Col, Image, Row, Table, Tabs, Typography } from "antd";
import { ColumnsType } from "antd/es/table";

const peopleColumns: ColumnsType<PersonDataType> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (_, record) => (
      <div className="grid grid-cols-2">
        <Image
          style={{}}
          preview={false}
          width="32px"
          src={record.profileUrl}
        />
        <span style={{ paddingLeft: "0px", fontWeight: 600, color: "#4B5563" }}>
          {record.name}
        </span>
      </div>
    ),
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "year",
  },
  {
    title: "Year Joined",
    dataIndex: "yearJoined",
    key: "yearJoined",
    responsive: ["md"],
  },
  {
    title: "Office",
    dataIndex: "officeLocation",
    key: "officeLocation",
    responsive: ["md"],
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    responsive: ["md"],
  },
  {
    title: "Phone",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "LinkedIn",
    dataIndex: "linkedinUrl",
    key: "linkedinUrl",
    render: (_, record) =>
      record.linkedinUrl === null ? (
        <>-</>
      ) : (
        <>
          <a href={record.linkedinUrl}>
            <LinkedIn />
          </a>
        </>
      ),
    responsive: ["md"],
  },
];

type PeopleTableProps = {
  label?: string;
  list: PersonDataType[];
};

function checkNull(value) {

  if (value === null) return "-";
  return value;
}

export const PeopleTable = ({ label, list = [] }: PeopleTableProps) => {
  const [people, setPeople] = useState<PersonDataType[]>();

  useEffect(() => {
    const newPeople: PersonDataType[] = list.map((person) => {
      const newPerson = {
        key: person.name,
        name: (person.email !== null) ? person.email : person.name,
        title: checkNull(person.title),
        yearJoined: checkNull(person.yearJoined),
        officeLocation: checkNull(person.officeLocation),
        email: checkNull(person.email),
        phoneNumber: checkNull(person.phoneNumber),
        linkedinUrl: person.linkedinUrl,
        profileUrl: person.profileUrl,
      };
      return newPerson;
    });
    setPeople(newPeople);
  }, [list]);

  return (
    <div key={label}>
      {label && (
        <Row>
          <Typography.Text
            style={{ fontWeight: 600, fontSize: "16px", marginBottom: "10px" }}
          >
            {label}
          </Typography.Text>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Table
            dataSource={people}
            columns={peopleColumns}
            size="small"
            bordered
          />
        </Col>
      </Row>
    </div>
  );
};

type TabbedPeopleTableProps = {
  tabs: { label: string; tableProps: PeopleTableProps }[];
};

export const TabbedPeopleTable = ({ tabs }: TabbedPeopleTableProps) => {


  const tabItems = tabs.map((tab, id) => {
    return {
      label: <span>{tab.label}</span>,
      key: id.toString(),
      children: <PeopleTable key={id} {...tab.tableProps} />,
    };
  });

  return <Tabs defaultActiveKey="0" items={tabItems} />;
};
export default PeopleTable;