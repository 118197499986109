import { Authenticator } from "@aws-amplify/ui-react";
import { Space } from "antd";
import { ReactComponent as Headset } from "@icons/headset.svg";
import logo from "@icons/reimaginlogov1.svg";
import hero from "@icons/HeroImagePlaceholderV2.svg";

import Icon from "@ant-design/icons";
import { View, useAuthenticator, Button } from "@aws-amplify/ui-react";

function Login() {
  const options = {
    hideSignUp: true,
  };

  const formFields = {
    signIn: {
      username: {
        label: "Email",
        placeholder: "Enter your Organization Email",
      },
    },
  };

  const components = {
    SignIn: {
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View className="flex justify-end pr-6" textAlign="center">
            <Button
              fontWeight="600"
              textDecoration="underline"
              fontFamily="Inter"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Forgot Password?
            </Button>
          </View>
        );
      },
    },
  };

  return (
    <div className="grid auto-rows-auto content-start ">
      <div className="pl-10 pt-10 lg:pt-20 lg:pl-20">
        <img width="200px" src={logo} alt="reimag.in" />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12  h-screen">
        <div className="row-auto lg:col-span-3"></div>

        <div className="col-span-6 auto-rows-auto">
          <div className="" style={{ fontWeight: 500, fontFamily: "Inter" }}>
            <Authenticator
              {...options}
              formFields={formFields}
              components={components}
            ></Authenticator>
          </div>

          <div
            className="text-center mt-8"
            style={{
              fontSize: "12px",
              fontWeight: 500,
              fontFamily: "Inter",
              color: "#003D50",
            }}
          >
            <Space>
              <Icon style={{ fontSize: "130%" }} component={Headset} />
              For support email us at help@reimag.in
            </Space>
          </div>

          <div
            className="text-center mx-10 lg:mx-0"
            style={{
              fontSize: "12px",
              fontWeight: 500,
              fontFamily: "Inter",
              color: "#003D50",
            }}
          >
            <p className="mt-16">
              By clicking “Log In” above, you acknowledge that you have read and
              understood, and agree to Reimag.in's Terms & Conditions and
              Privacy Policy.
            </p>
            <p className="mt-8">
              ©2023 Reimag.in Group LTD.| All Rights Reserved
            </p>
          </div>
        </div>

        <div className="col-span-3 flex justify-end pb-28">
          <img src={hero} alt="Reimag.in" />
        </div>
      </div>
    </div>
  );
}

export default Login;
