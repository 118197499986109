
import { useParams } from "react-router";
import CalendarChart, { DetailTabs, LoadingBarChart}  from "./CalendarChart";
import useFundQuery from "@hooks/api/useFundQuery";
import useActiveFund from "@hooks/useActiveFund";
import EmptyTable from "@components/EmptyTable";
import { formatMillions } from "src/utils";
import { useState, useEffect } from "react";

import log from "loglevel";

const GrowthDetail = (): JSX.Element => {

  const { activeFundId } = useActiveFund();
  const { id } = useParams();
  const [period, setPeriod] = useState(0);
  const [finLabel] = useState("Financial Performance");
  const [salesLabel] = useState("Sales");
  const [custLabel] = useState("Customers");

  const { data, isLoading } = useFundQuery(
    activeFundId,
    (data) => `/funds/${data.id}/portfolios/${id}/growth-performance`,
    {
      queryKey: ["funds", activeFundId, "portfolio", "detail", "growth", id],
    }
  );

  useEffect(() => {
    if (!isLoading) {
      setItems([
        { label: finLabel, key: "0", children: <CalendarChart label={finLabel} period={period} data={data.financials} formatter={formatMillions}/> },
        { label: salesLabel, key: "1" , children: <EmptyTable />},
        { label: custLabel, key: "2", children: <EmptyTable />}
      ]);
    }
    else {
      setItems([
        { label: finLabel, key: "0", children: <LoadingBarChart />},
        { label: salesLabel, key: "1" , children: <EmptyTable />},
        { label: custLabel, key: "2", children: <EmptyTable />}
      ]);     
    }
  }, [isLoading, period]);

  const [items, setItems] = useState([]);

  log.debug("Growth detail:", data);

  return (
    <DetailTabs items={items} setPeriod={setPeriod} />
  );
};

export default GrowthDetail;