import Sider from "antd/es/layout/Sider";
import { Typography, Image } from "antd";
import { ReactComponent as activeDashboardIcon } from "@icons/dashboard_active.svg";
import { ReactComponent as activeGoalsIcon } from "@icons/goals_active.svg";
import { ReactComponent as activeInsightsIcon } from "@icons/insights_active.svg";
import { ReactComponent as activeReportsIcon } from "@icons/reports_active.svg";
import { ReactComponent as activePortfolioIcon } from "@icons/portfolio_active.svg";
import { ReactComponent as defaultDashboardIcon } from "@icons/dashboard_default.svg";
import { ReactComponent as defaultGoalsIcon } from "@icons/goals_default.svg";
import { ReactComponent as defaultInsightsIcon } from "@icons/insights_default.svg";
import { ReactComponent as defaultReportsIcon } from "@icons/reports_default.svg";
import { ReactComponent as defaultPortfolioIcon } from "@icons/portfolio_default.svg";
//import { ReactComponent as defaultResourcesIcon } from "@icons/resources_default.svg";
import { useNavigate, NavLink } from "react-router-dom";

import Icon from "@ant-design/icons";
import { COLOR_BRAND_PRIMARY } from "@constants/colors";

import { Disclosure } from "@headlessui/react";
import { ReactComponent as ArrowDown } from "@icons/arrow-down-s-line.svg";

const navigation = [
  {
    name: "Dashboard",
    href: "/",
    key: "0",
    defaultIcon: defaultDashboardIcon,
    activeIcon: activeDashboardIcon,
  },
  {
    name: "Portfolio",
    href: "/portfolio",
    key: "1",
    defaultIcon: defaultPortfolioIcon,
    activeIcon: activePortfolioIcon,
  },
  {
    name: "Insights",
    href: "/insights",
    key: "2",
    defaultIcon: defaultInsightsIcon,
    activeIcon: activeInsightsIcon,
  },
  {
    name: "Goals",
    key: "3",
    defaultIcon: defaultGoalsIcon,
    activeIcon: activeGoalsIcon,
    children: [
      { name: "Fund Level", href: "/fundgoals", key: "3.1" },
      { name: "Company Level", href: "/companygoals", key: "3.2" },
    ],
  },
  {
    name: "Reports",
    key: "4",
    defaultIcon: defaultReportsIcon,
    activeIcon: activeReportsIcon,
    children: [
      { name: "Fund Level", href: "/fundreports", key: "4.1" },
      { name: "Company Level", href: "/companyreports", key: "4.2" },
    ],
  },
];

const { Text } = Typography;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ReimaginSider(props) {
  const navigate = useNavigate();

  const siderStyle: React.CSSProperties = {
    textAlign: "left",
    color: "#2D324E",
    fontWeight: 300,
    backgroundColor: "#ffffff",
    borderTop: "0px",
    borderRightWidth: "1px",
    borderRightStyle: "solid",
    borderRightColor: "#F2F2F2",
  };

  return (
    <Sider style={siderStyle} breakpoint="lg" collapsedWidth="0">
      <div className="mt-8 flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6">
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    {
                      !item.children ? 
                      (
                        <NavLink
                          to={item.href}
                          onClick={() => navigate(item.href)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className={classNames(
                            item.key === props.siderIndex
                              ? ""
                              : "hover:bg-gray-50",
                            "block flex-align-center rounded-md py-2 pr-2 pl-8 text-sm leading-6 font-medium text-gray-700"
                          )}
                        >
                          {item.key === props.siderIndex ? (
                            <>
                              <Icon
                                className="mr-2"
                                style={{
                                  fontSize: "150%",
                                  color: COLOR_BRAND_PRIMARY,
                                }}
                                component={item.activeIcon}
                              />
                              <Text
                                style={{
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  color: COLOR_BRAND_PRIMARY,
                                }}
                              >
                                {item.name}
                              </Text>
                            </>
                          ) : (
                            <>
                              <Icon
                                className="mr-2"
                                style={{ fontSize: "150%", color: "white" }}
                                component={item.defaultIcon}
                              />
                              <Text style={{ fontWeight: 500, fontSize: "12px" }}>
                                {item.name}
                              </Text>
                            </>
                          )}
                        </NavLink>
                      ) : 
                      (
                        <Disclosure
                          as="div"
                          defaultOpen={item.key === props.siderIndex[0]}
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.key === props.siderIndex
                                    ? "bg-gray-50 pl-8 py-2 justify-between"
                                    : "hover:bg-gray-50",
                                  " pl-8 py-2 flex w-full text-left rounded-md text-sm leading-6 font-medium text-gray-700 justify-between items-center"
                                )}
                              >
                                <span
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <Icon
                                    className="mr-2"
                                    style={{ fontSize: "150%", color: "white" }}
                                    component={item.defaultIcon}
                                  />
                                  <Text
                                    className="mr-8"
                                    style={{ fontWeight: 500, fontSize: "12px" }}
                                  >
                                    {item.name}
                                  </Text>
                                </span>
                                <ArrowDown
                                  className={classNames(open ? "rotate-180" : "")}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="mt-1 pl-6 ">
                                {item.children.map((subItem) => (
                                  <li key={subItem.name}>
                                    <Disclosure.Button
                                      as="a"
                                      onClick={() => navigate(subItem.href)}
                                      //href={subItem.href}
                                      className={classNames(
                                        subItem.key === props.siderIndex
                                          ? ""
                                          : "hover:bg-gray-50",
                                        "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700"
                                      )}
                                    >
                                      {subItem.key === props.siderIndex ? (
                                        <Text
                                          style={{
                                            fontWeight: 600,
                                            fontSize: "12px",
                                            color: COLOR_BRAND_PRIMARY,
                                          }}
                                        >
                                          {subItem.name}
                                        </Text>
                                      ) : (
                                        <Text
                                          style={{
                                            fontWeight: 500,
                                            fontSize: "12px",
                                          }}
                                        >
                                          {subItem.name}
                                        </Text>
                                      )}
                                    </Disclosure.Button>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )
                    }
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>

        <div className="" style={{ marginTop: "80px", borderWidth: "0px" }}>
         {/** 
          <NavLink
            //onClick={() => navigate("/")}
            to="/"
            className="hover:bg-gray-50, block rounded-md py-2 pl-6 text-sm leading-6 font-medium text-gray-700"
          >
            <Icon
              className="mr-2"
              style={{ fontSize: "150%", color: "white" }}
              component={defaultResourcesIcon}
            />
            <Text style={{ fontWeight: 500, fontSize: "12px" }}>Resources</Text>
          </NavLink>
                                      */}
          <Image
            preview={false}
            width={100}
            style={{ marginLeft: "25px", marginBottom: "0px" }}
            src="/reimagin_banner.png"
          />
        </div>
      </div>
    </Sider>
  );
}

export default ReimaginSider;
