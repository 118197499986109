import { Table } from "antd";


function EmptyTable () {
  return (
    <>
      <Table />
    </>
  );
}

export default EmptyTable;
