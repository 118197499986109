/**
 * @author  Regimag.in
 * @description  This hook is used fetch static configurations data
 *
 */

import useApiQuery from "./useApiQuery";

type DashboardQueryType = (id: string) => any;
const useDashboardQuery: DashboardQueryType = (id: string) => {
  const result = useApiQuery(`/funds/${id}/dashboard-stats`, {
    queryKey: ["dashboard", id],
    enabled: !!id,
  });
  return result;
};

export default useDashboardQuery;
