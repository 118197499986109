import Page from "@screens/Page";
import { Summary } from "@components/Summary";
//import { Modules } from "@components/Modules";
import { PortfolioTable } from "@components/PortfolioTable";
import Insights from "@components/Insights";
import useActiveFund from "@hooks/useActiveFund";
import useDashboardQuery from "@hooks/api/useDashboardQuery";
import { portfolioRowTransform } from "./Portfolio/Portfolio";
import useFundQuery from "@hooks/api/useFundQuery";

import log from "loglevel";

const Dashboard = () => {
  const { activeFundId } = useActiveFund();
  const { data } = useDashboardQuery(activeFundId);
  log.debug("dashboardData2:", data);

  //Cache this query early
  useFundQuery(
    activeFundId,
    "portfolios",
    {
      queryKey: ["portfolios", activeFundId, "ALL"],
    }
  ); 
  
  return (
    <Page siderIndex="0">
      <Summary />
      {/*<Modules /> Not working yet, but leave here*/}
      <PortfolioTable
        dataSource={(data?.portfolios || []).map(portfolioRowTransform)}
      />
      <div className="pt-8" />
      <Insights />
    </Page>
  );
};

export default Dashboard;
