import { Button, Card, Image, Tooltip, Row, Col, Typography } from "antd";

const { Text } = Typography;


export function ReportList(props) {
  const list = props.reports;

  function handleDownload() {
    list.map((report) => {
      return downloadFile(report.url);
    });
  }

  return (
    <>
      <Card
        headStyle={{ fontWeight: 500, fontSize: "16px", color: "#2D324E" }}
        style={{ marginBottom: "20px" }}
        title={props.title}
        extra={
          <Button
            onClick={handleDownload}
            style={{
              marginTop: "0px",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: 500,
            }}
          >
            Download All
            <Image
              style={{ paddingLeft: "8px" }}
              preview={false}
              src="/images/download.png"
            />
          </Button>
        }
      >
        <Row>
          {list.map((report, index) => (
            <FileCard icon={props.icon} report={report} key={index} />
          ))}
        </Row>
      </Card>
    </>
  );
}

function downloadFile(file) {
  /*
  axios
    .get(file, {
      responseType: "blob",
    })
    .then((res) => {
      const parts = file.split("/");
      const filename = parts[parts.length - 1];

      fileDownload(res.data, filename);
    });
    */
    window.open(file, "_blank")
}


function FileCard(props) {
  function handleDownload() {

    downloadFile(props.report.url);
  }

  return (
    <div
      style={{
        padding: "10px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "lightgray",
        borderRadius: "15px",
        minWidth: "200px",
      }}
    >
      <Row align="middle" gutter={10}>
        <Col>
          <Image preview={false} src={props.icon} />
        </Col>
        <Col>
          <Row>
            <Tooltip placement="top" title={props.report.name}>
              <Text
                className="max-w-[200px] truncate"
                style={{ fontSize: "12px", fontWeight: 500, color: "#2D324E" }}
              >
                {props.report.name}
              </Text>
            </Tooltip>
          </Row>
          <Row>
            <Button
              onClick={handleDownload}
              style={{
                marginTop: "0px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderWidth: "0px",
              }}
            >
              <Image preview={false} src="/images/download.png" />
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ReportList;
