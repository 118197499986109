interface InsightsToggleButtonProps {
  label: string;
  isSelected: boolean;
  icon: string;
  style?: object;
  onClick: (label: string) => void;
}

export const InsightsToggleButton = ({
  onClick,
  label,
  isSelected,
  icon,
  style = {},
}: InsightsToggleButtonProps) => {
  return (
    <div
      onClick={() => {
        onClick(label);
      }}
      style={{
        display: "flex",
        borderRadius: 6,
        flexDirection: "row",
        paddingLeft: 8,
        paddingRight: 8,
        alignItems: "center",
        height: 25,
        border: `1px solid #${isSelected ? "00A2AC" : "D0D4DC"}`,
        cursor: "pointer",
        ...style,
      }}
    >
      <img
        style={{
          width: 14,
          height: 14,
          marginRight: 8,
        }}
        src={icon}
        alt="icon"
      />
      <div
        style={{
          fontFamily: "Inter",
          fontSize: 12,
          fontWeight: 500,
          color: isSelected ? "#00A2AC" : "#76798B",
        }}
      >
        {label}
      </div>
    </div>
  );
};
