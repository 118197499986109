import {
  ConfigProvider,
  Table,
  Typography,
  Row,
  Col,
  Image,
  Tooltip,
} from "antd";
import { useState, useEffect, useMemo } from "react";
import Icon from "@ant-design/icons";
//import { ReactComponent as Overall } from "@icons/Indicator_overall.svg";
import { ReactComponent as Available } from "@icons/Indicator_available.svg";
import { ReactComponent as Unavailable } from "@icons/Indicator_unavailable.svg";
import { ReactComponent as Verified } from "@icons/Indicator_verified.svg";
import useActiveFund from "@hooks/useActiveFund";
import useFundQuery from "@hooks/api/useFundQuery";
import { InsightsToggleButton } from "./InsightsToggleButton";

const { Text } = Typography;

// TODO - move to constants and a more functional approach. Avoid mutating global variables. LG
var columns0;
var columns1;
var columns2;

function formatImage(value) {
  if (value === "AVAILABLE")
    return <Icon style={{ fontSize: "130%" }} component={Available} />;
  if (value === "VERIFIED")
    return <Icon style={{ fontSize: "130%" }} component={Verified} />;
  if (value === "NOT-AVAILABLE")
    return <Icon style={{ fontSize: "130%" }} component={Unavailable} />;
  if (value === "NOT_AVAILABLE")
    return <Icon style={{ fontSize: "130%" }} component={Unavailable} />;

  return <>n/a</>;
}

//ASSUMPTION: i can trust data to list same set of companies in each metric
function createColumns(companies, basicColumns, companyIds) {
  columns0 = [...basicColumns];
  columns1 = [...basicColumns];
  columns2 = [...basicColumns];

  companies
    .map((company: any) => {
      return [
        createColumn(columns0, company, "completion", true, companyIds),
        createColumn(columns1, company, "performance", false, companyIds),
        createColumn(columns2, company, "improvements", false, companyIds),
      ];
    })
    .flat();
}

function createColumn(columns, key, field, useImage, companyIds) {
  const header = (
    <span className="flex items-center insights">
      <img
        src={companyIds[key].profileUrl}
        alt="company"
        style={{ width: 14, height: 14, borderRadius: 7, marginRight: 8 }}
      />
      <span
        style={{
          color: "#76798B",
          fontFamily: "Inter",
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        {companyIds[key].displayName}
      </span>
    </span>
  );

  columns.push({
    title: () => header,
    dataIndex: key,
    key: key,
    render: (_, record, rowIndex) => {
      if (useImage)
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {formatImage(record[key][field])}
          </div>
        );
      if (record[key][field] === null)
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {formatImage("NOT-AVAILABLE")}
          </div>
        );
      return <div style={{ textAlign: "center" }}>{record[key][field]}</div>;
    },
  });
}

export interface RowType {
  key: string;
  area: string;
  metrics: string;
}

type InsightsTableProps = {
  rows: RowType[];
  title: string;
  companies: string[];
  companyIds: {};
  rowScopes: number[];
  metric2description: {};
  timePeriod: string;
};

export const InsightsTable = ({
  rows,
  title,
  companies,
  companyIds,
  rowScopes,
  metric2description,
  timePeriod,
}: InsightsTableProps) => {
  const [currentView, setCurrentView] = useState("Completion");
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { activeFundId } = useActiveFund();
  const { data: fundData } =
    useFundQuery(activeFundId);

  console.log("INSIGHTS:", fundData);

  const basicColumns = useMemo(
    () => [
      {
        title: "Area",
        dataIndex: "area",
        key: "area",
        rowScope: "row",
        render: (text, record, rowIndex) => {
          return (
            <div
              style={{
                paddingRight: 20,
                paddingLeft: 20,
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#2D324E",
                }}
              >
                {text}
              </Text>
            </div>
          );
        },
        onCell: (record, index) => {
          return { rowSpan: rowScopes[index] };
        },
      },
      {
        title: "Metric Details",
        dataIndex: "metric",
        key: "metric",
        render: (text, record, rowIndex) => {
          return (
            <Tooltip
              color="#00A2AC"
              placement="topLeft"
              title={metric2description[record.metric]}
            >
              <div
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                {text}
              </div>
            </Tooltip>
          );
        },
      },
    ],
    [metric2description, rowScopes]
  );

  useEffect(() => {
    createColumns(companies, basicColumns, companyIds);
    setTableData(rows);
    setColumns(columns0);
  }, [rows, companies, companyIds, basicColumns]);

  const viewChanged = (value): void => {
    setCurrentView(value);
    if (value === "Performance") setColumns(columns1);
    else if (value === "Improvements") setColumns(columns2);
    else setColumns(columns0);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
          components: {
            Radio: {
              colorPrimary: "#0CC2CB",
              colorPrimaryHover: "#0CC2CB",
            },
          },
        }}
      >
        <div
          style={{ textAlign: "left", marginBottom: "20px", marginTop: "20px" }}
        >
          <Text
            style={{
              textAlign: "left",
              color: "#2D324E",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Insights
          </Text>

          {tableData.length > 0 ? (
            <div
              style={{
                marginTop: "10px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "lightgray",
                borderRadius: "5px",
              }}
            >
              <Table
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#4B5563",
                  padding: "0px",
                }}
                className="insights"
                dataSource={tableData}
                columns={columns}
                bordered={false}
                size="small"
                pagination={false}
                title={() => (
                  <Row
                    style={{
                      height: 80,
                      alignItems: "center",
                      paddingLeft: 16,
                      paddingRight: 16,
                    }}
                    gutter={12}
                  >
                    <Col>
                      <Image
                        preview={false}
                        width="32px"
                        src={fundData?.imageUrl}
                      />
                    </Col>
                    <Col>
                      <Row>
                        <Text
                          style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#222222",
                          }}
                        >
                          {title}
                        </Text>
                      </Row>
                      <Row>
                        <Text
                          style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#777777",
                          }}
                        >
                          {timePeriod}
                        </Text>
                      </Row>
                    </Col>
                    <Col flex="auto"></Col>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <InsightsToggleButton
                          label="Completion"
                          isSelected={currentView === "Completion"}
                          icon="/images/insights/completion-selected.png"
                          style={{
                            marginRight: 8,
                          }}
                          onClick={(value) => viewChanged(value)}
                        />
                        <InsightsToggleButton
                          label="Performance"
                          isSelected={currentView === "Performance"}
                          icon="/images/insights/performance.png"
                          style={{
                            marginRight: 8,
                          }}
                          onClick={(value) => viewChanged(value)}
                        />
                        <InsightsToggleButton
                          label="Improvements"
                          isSelected={currentView === "Improvements"}
                          icon="/images/insights/improvements.png"
                          onClick={(value) => viewChanged(value)}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                footer={() => (
                  <Row
                    style={{
                      height: 52,
                      alignItems: "center",
                      backgroundColor: "#ffffff",
                      paddingRight: 10,
                    }}
                    justify="end"
                  >
                    <Icon
                      style={{ fontSize: "150%", marginRight: "4px" }}
                      component={Unavailable}
                    />
                    Data: Not Available
                    <Icon
                      style={{
                        fontSize: "150%",
                        marginLeft: "15px",
                        marginRight: "4px",
                      }}
                      component={Available}
                    />
                    Data: Available
                    <Icon
                      style={{
                        fontSize: "150%",
                        marginLeft: "15px",
                        marginRight: "4px",
                      }}
                      component={Verified}
                    />
                    Data: Verified
                  </Row>
                )}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </ConfigProvider>
    </>
  );
};
