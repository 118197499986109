/**
 * @author  Regimag.in
 * @description  This hook is used fetch fund data specific to the fund id
 *
 */

import useApiQuery, { ApiQueryProps } from "./useApiQuery";

const resolveEndpoint = (id: string, section: FundSectionType) => {
  if (section) {
    if (typeof section === "function") {
      return section({ id });
    }
    return `/funds/${id}/${section}`;
  } else {
    return `/funds/${id}`;
  }
};

type FundSectionType = string | ((data: any) => string);

type FundQueryType = (
  id: string,
  section?: FundSectionType,
  options?: ApiQueryProps
) => any;
const useFundQuery: FundQueryType = (
  id: string,
  section?: FundSectionType,
  options = {}
) => {
  const endpoint = resolveEndpoint(id, section);
  const queryKey = options.queryKey || [endpoint];
  return useApiQuery(endpoint, {
    enabled: !!id,
    ...options,
    queryKey,
  });
};

export default useFundQuery;
