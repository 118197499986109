import {
  ConfigProvider,
  Radio,
  Row,
  Skeleton,
  Tabs,
  Tag,
  Typography
} from "antd";
import { useEffect, useState } from "react";

import { ResponsiveBar } from "@nivo/bar";
import log from "loglevel";
import MetricRadio from "@components/MetricRadio";
import { ReactComponent as calendar1_selected } from "@icons/calendar1_selected.svg";
import { ReactComponent as calendar2_selected } from "@icons/calendar2_selected.svg";
import { ReactComponent as calendar3_selected } from "@icons/calendar3_selected.svg";
import { ReactComponent as calendar1_unselected } from "@icons/calendar1_unselected.svg";
import { ReactComponent as calendar2_unselected } from "@icons/calendar2_unselected.svg";
import { ReactComponent as calendar3_unselected } from "@icons/calendar3_unselected.svg";
import { ArrowDownOutlined, ArrowUpOutlined, BarChartOutlined, LineOutlined } from '@ant-design/icons';

const colors = ["#35C7D1", "#B7DA2C", "#6B9EFF", "#FF92D3", "#967FD3"]
const { Text } = Typography;


const defaultFormatter = (value) => {return value.toString()};
const quarterlyFormatter = (value) => {

  const parts = value.split("/");
  if (parts[0] === "Q1")
    return value;
  return parts[0];
};

function isOdd(num) { return num % 2;}

const monthlyFormatter = (value) => {

  const parts = value.split("/");
  const month = parseInt(parts[0]);
  if (month === 1) return value;
  //  return "Q" + parts[0] + "\n" + parts[1];
  if (isOdd(month)) return parts[0];
  return "";
};

const bottomAxisFormatters = [
  defaultFormatter,
  quarterlyFormatter,
  monthlyFormatter
];

const bottomAxisLabel = [
  "Year",
  "Quarters",
  "Months"
];

const CalendarChart = (props): JSX.Element => {

  const [processedData, setProcessedData] = useState([]);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    
    const keyList = [];
    const periodList = [];
    //props.tabBarExtraContent
    processPeriod(props.data.yearly, 
      "year", 
      (item) => {return item.year},
      (a, b) => { 
        if (a["year"] > b["year"]) return 1;
        if (a["year"] < b["year"]) return -1;
        return -1;
      },
      keyList,
      periodList
    );

    processPeriod(props.data.quarterly, 
          "quarter", 
          (item) => {return "Q" + item.quarter + "/" + item.year},
          (a, b) => { 
            if (a["year"] > b["year"]) return 1;
            if (a["year"] < b["year"]) return -1;
            if (a["quarter"] > b["quarter"]) return 1;
            return -1;
          },
          keyList,
          periodList
    );

    processPeriod(props.data.monthly, 
      "month", 
      (item) => {return item.month + "/" + item.year},
      (a, b) => { 
        if (a["year"] > b["year"]) return 1;
        if (a["year"] < b["year"]) return -1;
        if (parseInt(a["month"]) > parseInt(b["month"])) return 1;
        return -1;
      },
      keyList,
      periodList
    );

    setKeys(keyList);
    setProcessedData(periodList);
  }, []);

  function processPeriod(data, periodName, labelFn, sortFn, keyList, periodList) {
    const newKeys = []
    const periods = {};
    data.forEach((item) => {
      
      const label = labelFn(item);
      let period = periods[label];

      if (period === undefined) {
        period = {"period": label, 
                  "year": item.year}
        period[periodName] = item[periodName];
    
        periods[label] = period;
      }

      period[item["metricName"]] = parseInt(item.total);
      period[item["metricName"] + "%"] = parseInt(item.percentageChange);

      let keyIndex = newKeys.indexOf(item["metricName"]);
      if (keyIndex < 0)  newKeys.push(item["metricName"]);
    });

    periodList.push(Object.values(periods).sort((a, b) => sortFn(a, b)));
    keyList.push(newKeys);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          borderRadius: 12,
        },
        components: {
          Radio: {
            colorPrimary: "#0CC2CB",
          },
        },
      }}
    >
      <div style={{height:"500px", backgroundColor:"white"}}>

        {
          processedData.length === 0 ? 
          <Skeleton avatar active paragraph={{ rows: 4 }} />: 
          <Chart  data={processedData[props.period]} 
                  label={props.label}
                  keys={keys[props.period]} 
                  formatter={props.formatter} 
                  axisFormatter={bottomAxisFormatters[props.period]} 
                  axisLabel={bottomAxisLabel[props.period]}/>
        }
        
      </div>
    </ConfigProvider>
  );
};

function Chart (props) {

  log.debug("chart:", props);


  function getColor(value) {
    return colors[props.keys.indexOf(value.id)]
  }

  function format (value) {
  
    if (props.formatter) return props.formatter(value);
    return value;
  }

  function formatBottomAxis (value) {

    return props.axisFormatter(value);
  }


  function formatStatistic(percValue) {

    // i think zero values get stripped from Nivo structure
    let color = '#0056D6';
    let icon = <LineOutlined />;
    let text = (percValue === undefined) ? "0" : percValue;

    if (percValue < 0) {
      color = '#cf1322';
      icon = <ArrowDownOutlined />;
    }
    else if (percValue > 0) {
      color = '#3f8600';
      icon = <ArrowUpOutlined />;
    }

    return <Tag
              style={{
                borderRadius: "20px",
                marginLeft: "10px"
              }}            
            >
              <div style={{
                color: color
              }}  >{text}%{icon}</div>
            </Tag>
  }

  function formatTooltip(value) {


    const rows = [];
    props.keys.forEach((item, index) => {

      const pKey = item + "%";
      const color = colors[props.keys.indexOf(item)] ;
      const formattedNumber = (value.data[item] === undefined) ? "n/a" : value.data[item].toLocaleString("en-US");

      rows.push(<Row key={index + "a"} style={{marginTop:"10px"}}>{item}</Row>);
      rows.push(<Row key={index + "b"}> 
                  <div className="flex items-center">
                  <div className={"w-3 h-3"} style={{backgroundColor:color, marginRight: "10px"}}></div>
                  {formattedNumber}{formatStatistic(value.data[pKey])}
                  </div>
                </Row>);
    }) 

    return (
      <div
          style={{
              background: 'white',
              padding: '9px 12px',
              borderRadius: "12px",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
          }}
      >
          <Text>{props.label}</Text>
          {rows}
      </div>
    )
  }

  return (
    <ResponsiveBar
        tooltip={(value) => {
          return (formatTooltip(value));
        }} 
        role="application"
        data={props.data}
        indexBy="period"
        colors={(value) => getColor(value)}
        keys={props.keys}
        valueFormat={(value) => format(value)}
        label={(value) => ``}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -30,
          format: function(value){ 
            return format(value);
          }
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding:0,
          tickRotation: 0,
          legend: props.axisLabel,
          legendPosition: "middle",
          legendOffset: 28,
          format: function(value){ 
            return formatBottomAxis(value);
          }
        }}
        groupMode="grouped"
        margin={{ top: 50, right: 130, bottom: 70, left: 60 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-left",
            direction: "row",
            itemWidth: 120,
            itemHeight: 50,
            itemOpacity: 0.85,
            symbolSize: 16,
            translateY: 80,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
  );
};

export function CalendarChartRadioGroup (props) {

  const [icons, setIcons] = useState([calendar1_selected, calendar2_unselected, calendar3_unselected]);

  function periodChanged(event) {
    const { value } = event.target;

    if (value === "Yearly") {
      props.setPeriod(0);
      setIcons([calendar1_selected, calendar2_unselected, calendar3_unselected]);
    }
    else if (value === "Quarterly") {
      props.setPeriod(1);
      setIcons([calendar1_unselected, calendar2_selected, calendar3_unselected]);
    }
    else {
      props.setPeriod(2);
      setIcons([calendar1_unselected, calendar2_unselected, calendar3_selected]);
    }
  }

  return (
    <Radio.Group
      style={{ fontWeight: 600, marginBottom:"10px", marginRight:"5px"}}
      defaultValue="Yearly"
      onChange={periodChanged}
    >
      <MetricRadio value="Yearly" icon={icons[0]} />
      <MetricRadio value="Quarterly" icon={icons[1]}  />
      <MetricRadio value="Monthly" icon={icons[2]} />
    </Radio.Group>
  );
}

export function DetailTabs (props) {

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
            borderRadius: 9,
          },
          components: {
            Tabs: {
              colorText: "#76798B",
              colorPrimaryHover: "#0CC2CB",
              colorBorderSecondary:"#EBEDF1",
              colorBgContainer: "white",
            },
          },
        }}
      >    
      {
        <Tabs
          tabBarStyle={{marginBottom:"0px", marginTop: "10px", backgroundColor:"#F9FAFB"}}
          tabBarExtraContent={{ right: <CalendarChartRadioGroup setPeriod={props.setPeriod} /> }}
          style={{ backgroundColor:"#F9FAFB", borderLeftWidth:"1px", borderRightWidth:"1px", borderBottomWidth:"1px"}}
          type="card"
          defaultActiveKey={"0"}
          items={props.items}
        />
      }
      </ConfigProvider>
    </>
  );

}

export const LoadingBarChart = () => (
  <>
    <Skeleton.Node active={true} className="w-full h-full">
      <BarChartOutlined style={{ fontSize: '450px', color:"lightgray"}}/>
    </Skeleton.Node>
  </>
);


export default CalendarChart;