/**
 * @author  Regimag.in
 * @description  This hook is used fetch static configurations data
 *
 */

import useApiQuery from "./useApiQuery";

type ConfigurationsQueryType = () => any;
const useConfigurationsQuery: ConfigurationsQueryType = () => {
  return useApiQuery("/configurations", {
    refetchInterval: 1000 * 60 * 60 * 60, // 1 hour
  });
};

export default useConfigurationsQuery;
