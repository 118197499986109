import { CSSProperties } from "react";
import { COLOR_BLACK_PRIMARY } from "@constants/colors";
import { COLOR_WHITE_PRIMARY } from "@constants/colors";
import { CurrencyDataType } from "src/types/currency";

export const contentStyle: CSSProperties = {
  textAlign: "left",
  minHeight: 800,
  padding: 20,
  fontSize: "12px",
  fontWeight: 500,
  color: COLOR_BLACK_PRIMARY,
  backgroundColor: COLOR_WHITE_PRIMARY,
};

const defaultLocale = navigator.language;
export type FormatterOptionsType = {
  locale?: string;
};

const formatter = (locale, options = {}) =>
  new Intl.NumberFormat(locale || defaultLocale, options); // TODO - add locale with context

export const formatNumber = (
  value: number,
  options: any | FormatterOptionsType = {}
) => formatter(options?.locale || options).format(value);

export const formatCurrency = (
  value: number,
  currency: CurrencyDataType,
  options: any | FormatterOptionsType = {}
) => {
  return formatter(options?.locale, {
    style: "currency",
    currency: currency?.symbol || "USD",
  }).format(value);
};
export const formatDate = (value: string, { locale = defaultLocale } = {}) =>
  new Date(value).toLocaleDateString(locale, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

export const formatMillions = (
  value: number,
  options: any | FormatterOptionsType = {}
) => {
  let millions = value / 1000000;
  millions = Math.round(millions);

  return options.currency
    ? `${formatCurrency(millions, options.currency)}M`
    : `${millions}M`;
};

export const formatThousands = (
  value: number,
  options: any | FormatterOptionsType = {}
) => {
  let thousands = value / 1000;
  thousands = Math.round(thousands);

  return options.currency
    ? `${formatCurrency(thousands, options.currency)}K`
    : `${thousands}K`;
};


// array = input array
// part = size of the chunk
export const splitArray = (array: any[], part: number) => {
  var tmp = [];
  for (var i = 0; i < array.length; i += part) {
    tmp.push(array.slice(i, i + part));
  }
  return tmp;
};

type getYearsOptionsType = {
  duration: number;
  direction: "past" | "future";
  year?: number;
};
export const getYears = (
  config: getYearsOptionsType = { duration: 10, direction: "past" }
) => {
  const { duration, direction, year } = config;

  const envYear: number | null = process.env.REACT_APP_CURRENT_YEAR
    ? parseInt(process.env.REACT_APP_CURRENT_YEAR)
    : null;
  const currentYear: number = year || envYear || new Date().getFullYear();
  const increment = direction === "past" ? 1 : -1;
  const years = [];
  const startYear =
    direction === "past" ? currentYear - duration : currentYear + duration;

  for (
    let i = startYear;
    direction === "past" ? i <= currentYear : i >= currentYear;
    i = i + increment
  ) {
    years.push({
      value: i,
      label: i.toString(),
    });
  }
  return years;
};

const utils = {
  contentStyle,
  getYears,
  splitArray,
  formatNumber,
  formatCurrency,
  formatDate,
  formatMillions,
  formatThousands,
};
export default utils;
