import useGoalsPerformanceQuery from "@hooks/api/useGoalsPerformance";
import { Tabs, Typography } from "antd";
import { useMemo } from "react";
import { KeyItem } from "./PerformanceKeyItem";

const { Text } = Typography;

const PortfolioPerformanceItem = ({ goal, fundId }) => {
  const { data: performance} =
    useGoalsPerformanceQuery(fundId, goal.staticGranularMetricData.id);

  const years = useMemo(() => {
    let allYears = [];
    performance?.forEach((fundGoal) => {
      Object.keys(fundGoal.goalMetric.years).forEach((year) => {
        if (!allYears.includes(year)) {
          allYears.push(year);
        }
      });
    });
    allYears.sort();
    if (allYears.length === 0) {
      const latestYear = new Date().getFullYear() - 1;
      return [latestYear - 3, latestYear - 2, latestYear - 1, latestYear];
    }
    return allYears;
  }, [performance]);

  const keyedData = useMemo(() => {
    const keyedPerformanceData = {};
    performance?.forEach((fundGoal) => {
      keyedPerformanceData[fundGoal.company.id] = {
        data: {},
        performance: "-",
        // fundGoal,
        fundGoal: {
          target: fundGoal.goalMetric.fundTarget,
          date: fundGoal.goalMetric.fundTargetDate,
          operator: fundGoal.goalMetric.fundTargetOperator,
        },
      };
      let mostRecentTimestamp = null;
      let mostRecentValue = "-";
      Object.keys(fundGoal.goalMetric.years).forEach((year) => {
        keyedPerformanceData[fundGoal.company.id]["data"][year] = {};
        fundGoal.goalMetric.years[year].forEach((quarter) => {
          if (
            mostRecentTimestamp < `${year}${quarter}` ||
            mostRecentTimestamp === null
          ) {
            mostRecentTimestamp = `${year}${quarter}`;
            mostRecentValue = quarter.value;
          }
          keyedPerformanceData[fundGoal.company.id]["data"][year][
            quarter.quarter
          ] = quarter.value;
        });
      });
      keyedPerformanceData[fundGoal.company.id].performance = mostRecentValue;
    });
    return keyedPerformanceData;
  }, [performance]);

  return (
    <div>
      <table
        style={{
          width: "100%",
        }}
      >
        <thead
          style={{
            backgroundColor: "#F9FAFB",
            height: 72,
            borderBottom: "1px solid #E5E7EB",
          }}
        >
          <tr>
            <th
              style={{
                paddingLeft: 20,
                borderRight: "1px solid #E5E7EB",
              }}
              rowSpan={2}
            >
              Portfolio
            </th>
            <th
              style={{
                textAlign: "center",
                borderRight: "1px solid #E5E7EB",
              }}
              rowSpan={2}
            >
              Inv. Year
            </th>
            {years.map((year, index) => (
              <th
                style={{
                  textAlign: "center",
                  borderRight: "1px solid #E5E7EB",
                }}
                colSpan={4}
                key={`${year}-${index}`}
              >
                FY {year}
              </th>
            ))}
            <th
              style={{
                textAlign: "center",
                borderRight: "1px solid #E5E7EB",
              }}
              rowSpan={2}
            >
              Status
            </th>
            <th
              style={{
                paddingLeft: 20,
              }}
              rowSpan={2}
            >
              Performance
            </th>
          </tr>
          <tr>
            {years.map((year) =>
              [1, 2, 3, 4].map((quarter) => (
                <th
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid #E5E7EB",
                    borderTop: "1px solid #E5E7EB",
                  }}
                  key={`${year}-${quarter}`}
                >
                  Q{quarter}
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody>
          {performance?.map((fundGoal, index) => {
            const { company } = fundGoal;
            let goalStatus: "on-track" | "behind" | "delayed";

            if (keyedData[company.id]["performance"] === "-") {
              goalStatus = "behind";
            }
            // Condition for meeting target
            else if (
              new Function(
                `return ${keyedData[company.id]["performance"]} ${
                  keyedData[company.id]["fundGoal"]["operator"]
                } ${keyedData[company.id]["fundGoal"]["target"]}`
              )()
            ) {
              goalStatus = "on-track";
            } else {
              if (
                new Date(keyedData[company.id]["fundGoal"]["date"]) < new Date()
              ) {
                goalStatus = "delayed";
              } else {
                goalStatus = "behind";
              }
            }

            return (
              <tr
                style={{
                  height: 49,
                }}
                key={company.id}
              >
                <td
                  style={{
                    backgroundColor: "#F9FAFB",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    height: 49,
                    paddingLeft: 20,
                    fontWeight: "600",
                    fontSize: 12,
                    fontFamily: "Inter",
                    color: "#374151",
                    borderRight: "1px solid #E5E7EB",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  <img
                    alt="Profile URL"
                    style={{
                      width: 28,
                      height: 28,
                      borderRadius: 14,
                      marginRight: 10,
                    }}
                    src={company.profileUrl}
                  />
                  {company.displayName}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#4B5563",
                    borderRight: "1px solid #E5E7EB",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  2020
                </td>
                {years.map((year) =>
                  [1, 2, 3, 4].map((quarter) => (
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 500,
                        color: "#4B5563",
                        borderRight: "1px solid #E5E7EB",
                        borderBottom: "1px solid #E5E7EB",
                      }}
                      key={`${year}-${quarter}`}
                    >
                      {keyedData[company.id]["data"][year] === undefined
                        ? "-"
                        : keyedData[company.id]["data"][year][quarter]
                        ? keyedData[company.id]["data"][year][quarter]
                        : "-"}
                    </td>
                  ))
                )}
                <td
                  style={{
                    borderRight: "1px solid #E5E7EB",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      backgroundColor:
                        goalStatus === "on-track"
                          ? "#BCE127"
                          : goalStatus === "behind"
                          ? "#FFE119"
                          : "#FF6262",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </td>
                <td
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  {/* Performance container */}
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#E3EAF0",
                      height: 20,
                      position: "relative",
                    }}
                  >
                    {/* Performance*/}
                    <div
                      style={{
                        width:
                          keyedData[company.id]["performance"] === "-"
                            ? 0
                            : `${keyedData[company.id]["performance"]}%`,
                        backgroundColor: "#005587",
                        height: 20,
                        color: "#ffffff",
                      }}
                    />
                    {/* Fund goal*/}
                    <div
                      style={{
                        width: 2,
                        height: 20,
                        backgroundColor: "#FF33C6",
                        position: "absolute",
                        left: `${keyedData[company.id]["fundGoal"]["target"]}%`,
                        top: 0,
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* TABLE KEY */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          height: 46,
          paddingRight: 10,
        }}
      >
        <KeyItem
          style={{
            marginRight: 16,
          }}
          label="On track"
          color="#BCE127"
          type="circle"
        />
        <KeyItem
          style={{
            marginRight: 16,
          }}
          label="Behind"
          color="#FFE119"
          type="circle"
        />
        <KeyItem
          style={{
            marginRight: 32,
          }}
          label="Delayed"
          color="#FF6262"
          type="circle"
        />

        <KeyItem
          style={{
            marginRight: 16,
          }}
          label="Performance"
          color="#005587"
          type="line"
        />
        <KeyItem
          style={{
            marginRight: 16,
          }}
          label="Fund Goal"
          color="#FF33C6"
          type="line"
        />
        <KeyItem label="Company Goal" color="#0CC1CA" type="line" />
      </div>
    </div>
  );
};

export const PortfolioPerformance = ({ goals, fundId }) => {
  return (
    <div
      style={{
        marginTop: 40,
      }}
    >
      <Text
        style={{
          textAlign: "left",
          color: "#2D324E",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        Portfolio Performance
      </Text>
      <div
        style={{
          width: "100%",
          borderRadius: 12,
          border: "1px solid #E5E7EB",
          overflow: "hidden",
          marginTop: 20,
        }}
      >
        <div
          style={{
            backgroundColor: "#F9FAFB",
            paddingLeft: 16,
            height: 50,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: "#2D324E",
              fontSize: 12,
              fontFamily: "Inter",
              fontWeight: "600",
              wordWrap: "break-word",
            }}
          >
            Overarching Fund Commitments
          </div>
          <div
            style={{ width: 188, height: 0, border: "1px #0CC2CB solid" }}
          ></div>
        </div>
        <div className="performance-tabs">
          <Tabs
            defaultActiveKey={"0"}
            type="card"
            items={goals.map((goal, index) => ({
              label: (
                <Text style={{ color: "#2D324E", fontWeight: 500 }}>
                  {goal.staticGranularMetricData.displayName}
                </Text>
              ),
              key: goal.id,
              children: (
                <PortfolioPerformanceItem fundId={fundId} goal={goal} />
              ),
            }))}
          />
        </div>
      </div>
    </div>
  );
};
